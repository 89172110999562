import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { COLORS } from "../../../../utils/Style/styleGlobale";
import ListProduitSelectByCategorie from "./ListProduitSelectByCategorie";

const ProductsLists = ({ categories }) => {
  const [products, setProducts] = useState([]);
  const [cateSelect, setCateSelect] = useState("");
  const getAllProducts = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API}product/all/list`,
        withCredentials: true,
      });
      // console.log(res.data);
      setProducts(res.data);
    } catch (error) {
      console.log(error);
      return toast.error(
        "Une erreur est survenue lors du chargement des produits"
      );
    }
  };
  useEffect(() => {
    getAllProducts();
  }, []);

  return (
    <StyledProductsLists>
      {cateSelect && (
        <ListProduitSelectByCategorie
          cateSelect={cateSelect}
          setCateSelect={setCateSelect}
        />
      )}
      <div className="box-sous-parent-products-lists">
        <h2>Produits</h2>
        <ul className="ul-products-list">
          {categories &&
            categories.length > 0 &&
            categories.map((categorie) => (
              <li
                className="li-categorie"
                key={categorie._id}
                onClick={() => setCateSelect(categorie.nameCategorie)}
              >
                {categorie.nameCategorie}
              </li>
            ))}
        </ul>
      </div>
    </StyledProductsLists>
  );
};

export default ProductsLists;
const StyledProductsLists = styled.div`
  /* background: orange; */
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90%;
  width: 50%;
  position: relative;
  .box-sous-parent-products-lists {
    /* background: blue; */
    width: 100%;
    display: flex;
    height: 100%;
    padding-bottom: 10vh;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    /* overflow-y: scroll; */
  }
  .box-sous-parent-products-lists > h2 {
    width: 100%;
    text-align: center;
  }
  .box-sous-parent-products-lists > .ul-products-list {
    width: 100%;
    /* background: pink; */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    .li-categorie {
      background: ${COLORS.green};
      color: ${COLORS.btnColor};
      font-weight: 900;
      font-size: 1.1em;
      margin: 15px;
      padding: 10px;
      border-radius: 5px;
      display: block;
      cursor: pointer;
    }
  }
  .box-sous-parent-products-lists
    > .ul-products-list
    > .child-box-ul-products-lits {
    background: ${COLORS.blackgrey};
    display: flex;
    justify-content: space-between;
    /* flex-direction: column; */
    border-bottom: solid 2px white;
    border-right: solid 2px white;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 5px;
  }

  //width >= 425px
  @media screen and (max-width: 429px) {
    width: 100%;
    .box-sous-parent-products-lists > .ul-products-list {
      width: 100%;
    }
    .to-action-in-data {
      width: 30%;
    }
  }
`;
