import React, { useEffect, useState } from "react";
import { Data } from "../../Context/ContextData/ContextData";
import styled from "styled-components";
import { COLORS } from "../Style/styleGlobale";
import { MdAddCircle, MdCancel } from "react-icons/md";
import { OrderCurrent } from "../../Context/ContextOrderCurrent/ContextOrderCurrent";
import { toast } from "react-toastify";

const PopGlobal = () => {
  const [data, setData] = useState([]);
  const [product, setProduct] = useState("");
  const [options, setOptions] = useState("");
  const [prixProductOption, setPrixProductOption] = useState("");
  const [displayOption, setDisplayOption] = useState(false);
  const [noteOption, setNoteOption] = useState("");
  const [textSearch, setTextSearch] = useState("");
  const { dataMongo, dataToPopUp, setDataToPopUp } = Data();
  const { orderAllUser, setOrderAllUser, tableCurrent } = OrderCurrent();
  const displayDataCalling = () => {
    // console.log(dataMongo);
    // let data = [];
    if (dataMongo && dataMongo.length > 0) {
      dataMongo.map((item) => {
        Object.keys(item).map((key) => {
          if (key === dataToPopUp) {
            setData(item[key]);
          }
        });
      });
    }
  };
  const addProductInOrderCurrent = (name, price) => {
    const nameProd = noteOption ? `${name} (${noteOption})` : name;
    // const el = { name, price, note: noteOption ? noteOption : "" };
    const el = { name: nameProd, price };
    if (orderAllUser.length > 0) {
      // Si orderContext n'est pas vide, vérifier s'il existe déjà un index "dataToPopup"
      const index = orderAllUser.findIndex(
        (item) => Object.keys(item)[0] === dataToPopUp
      );
      if (index !== -1) {
        // Si un index "dataToPopup" existe, ajouter l'objet à cet index
        const updatedOrderContext = [...orderAllUser];
        updatedOrderContext[index][dataToPopUp].push(el);
        // setOrderContext(updatedOrderContext);
        setOrderAllUser(updatedOrderContext);
      } else {
        // Sinon, créer un nouvel index "Boissons" avec l'objet
        setOrderAllUser((prev) => [{ [dataToPopUp]: [el] }, ...prev]);
      }
    } else {
      // Si orderContext est vide, créer un nouvel index "dataToPopup" avec l'objet
      setOrderAllUser([{ [dataToPopUp]: [el] }]);
    }
    toast.success(`${name} ajouté à la table en cours...`);
  };

  const searchElement = (e) => {
    const searching = e.target.value.toLowerCase();
    setTextSearch(e.target.value);
    const filteredData = data.filter((el) =>
      el.nameProduct.toLowerCase().includes(searching)
    );
    setData(filteredData);
  };
  const actionToDisplayChooseOption = (name, infos, price) => {
    setDisplayOption(true);
    setProduct(name);
    setOptions(infos);
    setPrixProductOption(price);
  };

  const actionDisplayOptions = () => {
    return (
      <div className="pop-lil">
        <div className="child-of-pop-lil">
          <MdCancel
            className="close-lil-pop"
            onClick={() => setDisplayOption(false)}
          />
          <strong>{product && product}</strong>
          <span>Option : {options && options}</span>
          <span>Prix : {options && prixProductOption.toFixed(2)} €</span>
          <textarea onChange={(e) => setNoteOption(e.target.value)}></textarea>
          <MdAddCircle
            className="btn-pop-lil"
            onClick={() => addProductInOrderCurrent(product, prixProductOption)}
          />
        </div>
      </div>
    );
  };
  useEffect(() => {
    displayDataCalling();
    console.log(dataMongo);
  }, [dataToPopUp]);

  useEffect(() => {
    if (!textSearch) {
      displayDataCalling();
    }
  }, [textSearch]);
  return (
    <StyledPopGlobal>
      {displayOption && actionDisplayOptions()}
      <MdCancel
        onClick={() => setDataToPopUp("")}
        className="close-pop-Global"
      />
      <h2>
        Table N°{tableCurrent} - {dataToPopUp}
      </h2>
      <input
        type="search"
        onChange={(e) => searchElement(e)}
        placeholder="Recherche"
      />
      <ul>
        {data &&
          data.length > 0 &&
          data.map((el) => (
            <div key={el._id} className="box-to-el-dynamic-pop">
              <div className="box-el-dyna">
                <li>{el.nameProduct}</li>
                {el.infos && <li>{el.infos}</li>}
                <li>{el.price.toFixed(2)} €</li>
              </div>
              {!el.infos ? (
                <MdAddCircle
                  className="add-in-table"
                  onClick={() =>
                    addProductInOrderCurrent(el.nameProduct, el.price)
                  }
                />
              ) : (
                <MdAddCircle
                  className="add-in-table"
                  onClick={() =>
                    actionToDisplayChooseOption(
                      el.nameProduct,
                      el.infos,
                      el.price
                    )
                  }
                />
              )}
            </div>
          ))}
      </ul>
    </StyledPopGlobal>
  );
};

export default PopGlobal;

const StyledPopGlobal = styled.div`
  background: ${COLORS.greenTransparent};
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  position: absolute;
  z-index: 50;
  height: 100vh;
  width: 100%;
  padding: 10px;
  .close-pop-Global {
    color: ${COLORS.red};
    font-size: 1.5em;
  }
  h2 {
    text-align: center;
    color: white;
  }
  input {
    outline: none;
    border: none;
    border-radius: 5px;
    padding: 3px;
    font-size: 1.1em;
    margin: 10px 0px;
  }
  ul {
    height: 80%;
    display: flex;
    flex-direction: column;
    /* background: orange; */
    overflow-x: scroll;
  }
  ul > .box-to-el-dynamic-pop {
    /* background: pink; */
    margin-top: 15px;
    padding: 10px 0px;
    display: flex;
    border-bottom: dashed 2px white;
  }
  ul > .box-to-el-dynamic-pop > .box-el-dyna {
    width: 90%;
    display: flex;
    justify-content: space-between;
  }
  ul > .box-to-el-dynamic-pop > .add-in-table {
    width: 10%;
  }
  .pop-lil {
    margin-top: 50px;
    position: absolute;
    z-index: 51;
    width: 100%;
    height: 100vh;
    padding: 10px;
    background: ${COLORS.greenTransparent};
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .pop-lil > .child-of-pop-lil {
    width: 50%;
    height: 30vh;
    padding: 5px;
    background: orange;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .pop-lil > .child-of-pop-lil > textarea {
    resize: none;
    outline: none;
    border: none;
    border-radius: 5px;
    padding: 5px;
    height: 10vh;
  }
  .pop-lil > .child-of-pop-lil > .close-lil-pop {
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 1.5em;
    color: ${COLORS.red};
  }
  .pop-lil > .child-of-pop-lil > .btn-pop-lil {
    font-size: 1.5em;
  }
`;
