import React, { useState } from "react";
import styled from "styled-components";
import { Dynamic } from "../../../../Context/ContextDynamic/ContextDynamic";
import { toast } from "react-toastify";
import axios from "axios";

const Password = () => {
  const [password, setPassword] = useState("");
  const [passwordNew, setPasswordNew] = useState("");
  const [passwordConf, setPasswordConf] = useState("");
  const { user, logOutWhenDeleteAccount } = Dynamic();

  const handleChangeUpdate = async (e) => {
    e.preventDefault();
    if (!password || !passwordConf || !passwordNew)
      return toast.error("Tous les champs sont obligatoires");
    if (passwordNew !== passwordConf)
      return toast.error("Nouveau mot de passe correspond pas ");

    try {
      const res = await axios({
        method: "put",
        url: `${process.env.REACT_APP_API}user/update/${user._id}`,
        withCredentials: true,
        data: { email: user.email, password, newPassword: passwordNew },
      });
      //   console.log(res);
      if (res.data.error) return toast.error(res.data.error);
      if (res.data.sucess) {
        logOutWhenDeleteAccount();
        setPassword("");
        setPasswordConf("");
        setPasswordNew("");
        return toast.success(res.data.sucess);
      }
    } catch (error) {
      console.log(error);
      toast.error("Une erreur est survenue");
    }
  };
  return (
    <StyledPassword onSubmit={handleChangeUpdate}>
      <strong>Email : {user.email}</strong>
      <input
        type="password"
        placeholder="Mot de passe"
        onChange={(e) => setPassword(e.target.value)}
        value={password ? password : ""}
      />
      <input
        type="password"
        placeholder="Nouveau mot de passe"
        onChange={(e) => setPasswordNew(e.target.value)}
        value={passwordNew ? passwordNew : ""}
        autoComplete="new-password"
      />
      <input
        type="password"
        placeholder="Confirmation du mot de passe"
        onChange={(e) => setPasswordConf(e.target.value)}
        value={passwordConf ? passwordConf : ""}
        autoComplete="new-password"
      />
      <input type="submit" value="Modifier" />
    </StyledPassword>
  );
};

export default Password;

const StyledPassword = styled.form`
  /* background: blue; */
  width: 50%;
  display: flex;
  flex-direction: column;
  margin: 0px auto 10px;
  padding: 10px;
  strong {
    margin-bottom: 10px;
  }
  input {
    padding: 3px;
    border: none;
    outline: none;
    border-radius: 3px;
    font-size: 1em;
    margin-bottom: 5px;
  }
  input:last-child {
    width: 50%;
  }
`;
