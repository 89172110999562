import { createContext, useContext } from "react";

const ContextStatic = createContext();

export const ContextStaticProvider = ({ children }) => {
  const entries = [
    {
      name: "Les Petites Entrées",
      content: [
        "Velouté de butternut, éclats de châtaigne",
        "L'oeuf mayonnaise",
        "Carottes râpées à la menthe, raisins secs",
        "Salade de quinoa, zestes de citron et menthe",
        "La poireau vinaigrette",
        "La petite terrine de campagne",
        "La petite faisselle",
      ],
    },
  ];
  const planches = [
    {
      name: "Les planches",
      content: [
        {
          name: "Planche de charcuterie",
          contenu:
            "Chiffonade de chorizo, coppa et rosette, terrine de compagne, beurre et cornichons",
        },
        {
          name: "Chiffonnade de charcuterie",
          contenu: "Chorizo, coppa et rosette, beurret et cornichons",
        },
        {
          name: "Grande planche de mixte",
          contenu:
            "Chiffonnade de chorizo, coppa et rosette, terrine de compagne, trio de fromages et tartinable de crème ciboulette",
        },
      ],
    },
  ];

  const chemine = [
    {
      name: "À la cheminée",
      content: [
        {
          name: "Andouillette grillée",
          content: "La recette traditionnelle de Troyes",
        },
        {
          name: "Assiette du grillardin",
          content:
            "Araignée de porc, saucisse de Toulouse, pièce du boucher, cœur de sucrine grillé et garniture au choix",
        },
        {
          name: "Entrecôte",
          content: "Son persillé, son gras soulignent son goût",
        },
        {
          name: "Pièce du boucher",
          content: "Pièce de bœuf sélectionnée par notre grillardin",
        },
        {
          name: "Super haché",
          content:
            "2 steaks hachés pur bœuf, Cabécou du Périgord® et lard grillé",
        },
        {
          name: "Steak haché pur bœuf façon bouchère",
          content: "Double steak haché pur bœuf façon bouchère",
        },
      ],
    },
  ];
  return (
    <ContextStatic.Provider value={{ entries, planches, chemine }}>
      {children}
    </ContextStatic.Provider>
  );
};

export const Static = () => useContext(ContextStatic);
