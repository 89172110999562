import React from "react";
import styled from "styled-components";
import { COLORS } from "../../utils/Style/styleGlobale";

const Conseil = ({ title }) => {
  return (
    <StyledConseil>
      <h1>{title}</h1>
      <div>
        <h2>Conseils pour les vendeurs</h2>
        <ul>
          <li>Être connecté à internet en permanence</li>
          <li>Ne pas fermer l'onglet</li>
          <li>Vérifier le bon Code De Partage avant de partager aux cients</li>
          <li>Désactiver le Code De Partage une fois votre journée terminer</li>
        </ul>
      </div>
      <div>
        <h2>Conseils pour les clients </h2>
        <span>
          Noulink permet pas aux clients de se créer un compte par conséquent la
          session du client dure 24h, si le vendeur n'efface pas votre commande,
          après 24h le même pseudo ne fonctionnera pas.{" "}
        </span>
        <strong>
          Si vous souhaitez vous inscrire veuillez nous le communiquer sur notre
          insta : noulinkg
        </strong>{" "}
        <ul>
          <li>Être connecté à internet en permanence</li>
          <li>Ne pas fermer l'onglet</li>
          <li>
            Si le code de partage fonctionne pas, il est possible qyu'il n'est
            plus actif ou que le vendeur a terminé sa journée
          </li>
          <li>Votre session dure 24h</li>
        </ul>
      </div>
    </StyledConseil>
  );
};

export default Conseil;

const StyledConseil = styled.div`
  padding: 5px;
  h1 {
    font-size: 1.3em;
    text-align: center;
    border-bottom: solid 2px ${COLORS.btnColor};
    margin-bottom: 15px;
  }
  div {
    border-bottom: solid 1px ${COLORS.blackgrey};
    margin-bottom: 10px;
    padding-bottom: 10px;
    ul > li {
      list-style: circle;
      font-size: 1em;
    }
    span,
    strong {
      display: block;
      color: ${COLORS.btnColor};
      font-size: 1em !important;
      margin: 5px 0px;
    }
    strong {
      background: ${COLORS.grey};
      border-radius: 3px;
      padding: 5px;
      font-size: 1em;
    }
  }
  div > h2 {
    font-size: 1em;
    text-align: center;
  }
`;
