import React from "react";
import styled from "styled-components";

const Status = () => {
  return (
    <StyledStatus>
      <strong>Admin</strong>
    </StyledStatus>
  );
};

export default Status;

const StyledStatus = styled.div`
  background: greenyellow;
  width: 20%;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  strong {
    color: blueviolet;
  }
`;
