import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Dynamic } from "../../../Context/ContextDynamic/ContextDynamic";
import { toast } from "react-toastify";
import { FaWindowClose } from "react-icons/fa";
import axios from "axios";
import { COLORS } from "../../../utils/Style/styleGlobale";

const PopProductsClientFast = ({
  setCategorieSelect,
  categorieSelect,
  setOrderFast,
  orderFast,
}) => {
  //dans setOrderFast qu'on doit mettre les commande ajouté
  const [products, setProducts] = useState([]);
  const [nameOption, setNameOption] = useState("");
  const [optionOptions, setOptionOptions] = useState("");
  const [prixSelect, setPrixSelect] = useState("");
  const [textSaisiOption, setTextSaisiOption] = useState("");
  const { cookies, setLoading } = Dynamic();

  const getProductsWithCategorie = async () => {
    setLoading(true);
    try {
      const res = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API}public/all/product`,
        withCredentials: true,
        data: {
          id: cookies.accesClient.idComp,
          categorie: categorieSelect,
        },
      });
      // console.log(res);
      if (res.data.error) {
        setLoading(false);
        return toast.error(res.data.error);
      }
      if (res.data.length > 0) {
        setLoading(false);
        setProducts(res.data);
      }
    } catch (error) {
      console.log(error);
      return toast.error("Une erreur est survenue du côté serveur");
    }
  };
  const displayDataCatch = () => {
    const addInOrder = (name, price) => {
      const nameProd = textSaisiOption
        ? `${name} - (${textSaisiOption})`
        : name;
      const productToAdd = {
        name: nameProd,
        price: prixSelect ? prixSelect : price,
      };
      // console.log(nameProd);
      // console.log(productToAdd);
      // console.log(categorieSelect);
      if (orderFast.length > 0) {
        const index = orderFast.findIndex(
          (item) => Object.keys(item)[0] === categorieSelect
        );

        if (index !== -1) {
          //si un index exist alors on ajoute l'objet(productToAdd) a cet index
          const updatedOrderFast = [...orderFast];
          updatedOrderFast[index][categorieSelect].push(productToAdd);
          setOrderFast(updatedOrderFast);
        } else {
          //sinon on créer un nouveau index
          setOrderFast((prev) => [
            { [categorieSelect]: [productToAdd] },
            ...prev,
          ]);
        }
      } else {
        //orderfast est vide, on créer un nouveau index avec la variable categorieSelect
        setOrderFast([{ [categorieSelect]: [productToAdd] }]);
      }
      //pour fermer le pop et remettre tout a zéro
      setNameOption("");
      setOptionOptions("");
      setPrixSelect("");
      setTextSaisiOption("");
      toast.success(`${nameProd} ajouté dans votre panier`);
    };
    const addCurrent = (name, infos, price) => {
      if (infos) {
        setNameOption(name);
        setOptionOptions(infos);
        setPrixSelect(price);
      } else {
        addInOrder(name, price);
        // toast.success(name);
      }
    };

    const chooseOption = () => {
      const closeOptionText = () => {
        setNameOption("");
        setOptionOptions("");
        setPrixSelect("");
        setTextSaisiOption("");
      };
      return (
        <div className="the-selection-div">
          <FaWindowClose className="close-option" onClick={closeOptionText} />
          <strong>Votre selection</strong>
          <span>Produit : {nameOption}</span>
          <span>Option : {optionOptions}</span>
          <span>Prix : {prixSelect.toFixed(2)} €</span>
          <input
            type="text"
            placeholder="Choisir votre option"
            value={textSaisiOption ? textSaisiOption : ""}
            onChange={(e) => setTextSaisiOption(e.target.value)}
          />
          <button onClick={() => addInOrder(nameOption)}>Ajouter</button>
        </div>
      );
    };
    if (products && products.length > 0) {
      return (
        <ul className="list-product-catch">
          {nameOption && optionOptions && chooseOption()}
          {products.map((product, index) => (
            <div
              key={index}
              onClick={() =>
                addCurrent(product.nameProduct, product.infos, product.price)
              }
            >
              <div className="plus-info">
                <li>{product.nameProduct}</li>
                {product.infos && (
                  <li className="info-op">Option : {product.infos}</li>
                )}
              </div>
              <li className="the-price">{product.price.toFixed(2)} €</li>
            </div>
          ))}
        </ul>
      );
    }
  };
  useEffect(() => {
    getProductsWithCategorie();
  }, []);
  return (
    <StyledPopProductsClientFast>
      <div className="box-display-product-when-select-categorie">
        <FaWindowClose
          onClick={() => setCategorieSelect("")}
          className="icon-to-close"
        />
        <span>
          Categorie : <strong>{categorieSelect}</strong>
        </span>
        {displayDataCatch()}
      </div>
    </StyledPopProductsClientFast>
  );
};

export default PopProductsClientFast;

const StyledPopProductsClientFast = styled.div`
  position: fixed;
  z-index: 40;
  height: 100vh;
  width: 100%;
  background: ${COLORS.greenTransparent};
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  border-radius: 10px;
  align-items: center;
  padding: 5px;

  .box-display-product-when-select-categorie {
    background: ${COLORS.grey};
    width: 100%;
    position: relative;
    display: flex;
    border-radius: 10px;
    flex-direction: column;
    .icon-to-close {
      position: absolute;
      right: 10px;
      top: 10px;
      color: ${COLORS.red};
      font-size: 1.2em;
    }
  }
  .box-display-product-when-select-categorie > span {
    text-align: center;
    margin-bottom: 5px;
  }
  .list-product-catch {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    /* background: blue; */
    height: 60vh;
    overflow-y: scroll;
  }
  .list-product-catch::-webkit-scrollbar {
    display: none;
  }
  .list-product-catch > div {
    background: ${COLORS.green};
    position: relative;
    margin: 0px auto;
    padding: 10px;
    font-size: 1.2em;
    border-radius: 3px;
    width: 80%;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    li {
      color: white;
    }
    .the-price {
      position: absolute;
      border-radius: 3px;
      background: ${COLORS.greenTransparent};
      top: 0px;
      right: 0px;
      color: white;
    }
  }
  .list-product-catch > div:active {
    background: ${COLORS.greenTransparent};
  }
  .the-selection-div {
    background: ${COLORS.greenTransparent} !important;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    strong {
      text-align: center;
      margin-bottom: 5px;
    }
    .close-option {
      /* background: ${COLORS.green}; */
      color: white;
    }
    span {
      font-size: 0.7em;
      margin: 0px;
    }
    input {
      padding: 5px;
      font-size: 0.8em;
      border: none;
      border-radius: 5px;
      outline: none;
    }
    button {
      width: 30%;
      outline: none;
      border: none;
      border-radius: 5px;
      font-size: 0.8em;
      margin-top: 5px;
      background: ${COLORS.green};
      color: white;
    }
  }

  //width >= 425px
  @media screen and (max-width: 425px) {
    /* height: 70vh; */
    width: 90%;
    .list-product-catch > div {
      width: 100%;
      .plus-info > li {
        font-size: 0.8em;
      }
      .plus-info > .info-op {
        font-size: 0.5em;
      }
      .the-price {
        width: 15%;
        /* background: pink; */
        font-size: 0.8em;
      }
    }
  }

  //width =< 425px
  @media screen and (min-width: 425px) {
    /* height: 70vh; */
    width: 90%;
    .list-product-catch > div {
      width: 100%;
      .plus-info > li {
        font-size: 0.9em;
      }
      .plus-info > .info-op {
        font-size: 0.6em;
      }
      .the-price {
        font-size: 0.8em;
      }
    }
  }
`;
