import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ButtonNav from "../../utils/ButtonNav/ButtonNav";
import { Dynamic } from "../../Context/ContextDynamic/ContextDynamic";
import { MdTableBar } from "react-icons/md";
import { MdCancel } from "react-icons/md";
import TableCurrent from "../TableCurrent/TableCurrent";
import { OrderCurrent } from "../../Context/ContextOrderCurrent/ContextOrderCurrent";
import { COLORS } from "../../utils/Style/styleGlobale";

const SelectTable = ({ cancelAction }) => {
  const { setOrderAdding, orderAdding, setPopTableChoose } = Dynamic();
  const { tableCurrent, setTableCurrent } = OrderCurrent();
  const [selectTableCurrent, setSelectTableCurrent] = useState(0);
  const [checkIfTable, setCheckIfTable] = useState(false);
  const selectNum = () => {
    // console.log(orderAdding[0].table);
    if (checkIfTable) {
      setCheckIfTable(false);
      setSelectTableCurrent(0);
      setTableCurrent(0);
      return;
    }
    const whatTable = parseInt(selectTableCurrent);
    if (whatTable === 0) return alert("Veuillez choisir une table");
    setTableCurrent(selectTableCurrent);
    setPopTableChoose(false); //to close pop ChoosePop
  };

  //check if one table choosen👇
  useEffect(() => {
    if (tableCurrent !== 0) {
      setCheckIfTable(true);
    } else {
      setCheckIfTable(false);
    }
  }, [tableCurrent]);

  return (
    <StyledSelectTable>
      <MdCancel className="icon-cancel" onClick={() => cancelAction()} />
      <MdTableBar className="icon-table" />
      {checkIfTable ? (
        <strong>{tableCurrent}</strong>
      ) : (
        <input
          type="number"
          defaultValue={tableCurrent !== 0 && tableCurrent}
          placeholder="0"
          onChange={(e) => setSelectTableCurrent(e.target.value)}
        />
      )}
      {/* <ButtonNav
        text={checkIfTable ? "Modifier la table" : "Choisir"}
        actionClick={() => selectNum()}
        css={50}
      /> */}
      <button onClick={() => selectNum()} className="btn-u">
        {checkIfTable ? "Modifier la table" : "Choisir"}
      </button>
      {checkIfTable ? (
        <TableCurrent />
      ) : (
        <strong>Aucune Table selectionée</strong>
      )}
    </StyledSelectTable>
  );
};

export default SelectTable;

const StyledSelectTable = styled.div`
  width: 100%;
  /* background: yellow; */
  background: white;
  display: flex;
  height: 50vh;
  height: 80vh;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  position: relative;
  .btn-u {
    padding: 5px;
    border: none;
    outline: none;
    border-radius: 10px;
    width: 50%;
    box-shadow: 11px 11px 22px #d9d9d9, -11px -11px 22px #ffffff;
    color: grey;
    font-weight: 550;
    font-size: 1.2em;
  }
  .btn-u:active {
    box-shadow: inset 11px 11px 22px #d9d9d9, inset -11px -11px 22px #ffffff;
  }

  .icon-table {
    font-size: 5em;
    color: ${COLORS.green};
  }
  input {
    outline: none;
    border: none;
    border-radius: 5px;
    padding: 5px;
    border-bottom: solid 1px grey;
    margin: 10px;
  }
  strong {
    margin-top: 10px;
  }
`;
