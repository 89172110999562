import React, { useState } from "react";
import styled from "styled-components";
import { Dynamic } from "../../../../Context/ContextDynamic/ContextDynamic";
import { toast } from "react-toastify";
import axios from "axios";
import { COLORS } from "../../../../utils/Style/styleGlobale";
import { FaArrowCircleLeft } from "react-icons/fa";

const Connexion = ({ resetPassword, setResetPassword }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { setUser, formConnexionRegister, setFormConnexionRegister } =
    Dynamic();
  const handleConnexion = async (e) => {
    e.preventDefault();
    if (!email || !password) return toast.error("Les champs sont obligatoires");
    try {
      const res = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API}auth/login`,
        withCredentials: true,
        data: {
          email,
          password,
        },
      });
      // console.log(res);
      if (res.data.error) return toast.error(res.data.error);
      if (res.data.block) {
        setInterval(() => {
          window.location.href = "https://www.google.com";
        }, 3000);
        return toast.error(res.data.block);
      }
      if (res.data.redirection) {
        window.location.href = "https://www.google.com";
      }
      if (res.data.succes) return toast.success(res.data.succes);
      if (res.data.email) {
        setUser(res.data);
      }
    } catch (error) {
      console.log(error);
      return toast.error(
        "Une erreur est survenue lors de la tentative de connexion"
      );
    }
  };

  const handleInit = async (e) => {
    e.preventDefault();
    if (!email) return toast.error("Email est nécessaire");
    try {
      const res = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API}auth/reset/passport`,
        withCredentials: true,
        data: {
          email,
        },
      });
      // console.log(res);
      if (res.data.error) return toast.error(res.data.error);
      if (res.data.succes) {
        setResetPassword(!resetPassword);
        return toast.success(res.data.succes);
      }
    } catch (error) {
      console.log(error);
      return toast.error(
        "Une erreur est survenue lors de la tentative d'initialisation"
      );
    }
  };

  const handleForm = (e) => {
    e.preventDefault();
    if (resetPassword) {
      handleInit(e);
    } else {
      handleConnexion(e);
    }
  };

  return (
    <StyledConnexion onSubmit={(e) => handleForm(e)}>
      <strong onClick={() => setFormConnexionRegister(!formConnexionRegister)}>
        <FaArrowCircleLeft className="icon-arrow" />
        {!formConnexionRegister && "S'enregistrer"}
      </strong>
      <input
        type="email"
        placeholder="Email"
        onChange={(e) => setEmail(e.target.value)}
      />
      {!resetPassword && (
        <input
          type="password"
          placeholder="Mot de passe"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      )}
      <input
        type="submit"
        value={resetPassword ? "Initialisation" : "Connexion 😎"}
      />
    </StyledConnexion>
  );
};

export default Connexion;

const StyledConnexion = styled.form`
  background: ${COLORS.green};
  /* background: #749f68; */
  /* height: 50vh; */
  width: 40%;
  padding: 5px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  align-items: center;
  strong {
    font-size: 1.6em;
    display: flex;
    cursor: pointer;
    color: ${COLORS.btnColor};
    align-items: center;
    .icon-arrow {
      margin-right: 10px;
    }
  }
  input {
    width: 70%;
    padding: 5px;
    outline: none;
    border: none;
    margin-top: 15px;
    font-size: 1.1em;
    border-radius: 5px;
  }
  input:last-child {
    width: 50%;
    cursor: pointer;
    /* background: #749f68; */
    background: ${COLORS.btnColor};
    box-shadow: 2px 2px 5px ${COLORS.btnColor}, -2px -2px 5px ${COLORS.btnColor};
    color: white;
  }
  input:last-child:active {
    background: #749f68;
    box-shadow: inset 5px 5px 10px ${COLORS.btnColor},
      inset -5px -5px 10px ${COLORS.btnColor};
  }

  //tablette 768px
  @media screen and (max-width: 768px) {
    width: 60% !important;
  }

  //width =< 320px
  @media screen and (max-width: 320px) {
    width: 90%;
    strong {
      font-size: 1.5em;
    }
    input {
      width: 90%;
      font-size: 1em;
    }
  }

  //width >= 425px
  @media screen and (max-width: 430px) {
    width: 90% !important;
    strong {
      font-size: 1.5em;
    }
    input {
      width: 90%;
      font-size: 1em;
    }
  }

  //width =< 425px
  /* @media screen and (min-width: 425px) {
    width: 90%;
    strong {
      font-size: 1.5em;
    }
    input {
      width: 90%;
      font-size: 1em;
    }
  } */
`;
