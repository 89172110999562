import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { Dynamic } from "../../../../Context/ContextDynamic/ContextDynamic";
import Categorie from "./Categorie/Categorie";

const FormAddCategorie = ({
  categories,
  callGetCategoriesAgain,
  setCallGetCategoriesAgain,
}) => {
  const [categorie, setCategorie] = useState("");
  const { user } = Dynamic();
  const createCategorie = async (e) => {
    e.preventDefault();
    if (!categorie) return toast.error("Champs obligatoire");
    try {
      const res = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API}categorie/add`,
        withCredentials: true,
        data: { name: categorie, id: user._id },
      });
      // console.log(res);
      if (res.data.succes) {
        setCallGetCategoriesAgain(!callGetCategoriesAgain);
        setCategorie("");
        toast.success(res.data.succes);
      }
      if (res.data.error) return toast.error(res.data.error);
    } catch (error) {
      console.log(error);
      return toast.error("Une erreur est survenue");
    }
  };
  //to list categories
  return (
    <StyledFormAddCategorie>
      <h1>Création d'une catégorie</h1>
      <form onSubmit={(e) => createCategorie(e)}>
        <input
          type="text"
          placeholder="Nouvelle catégorie *"
          value={categorie ? categorie : ""}
          onChange={(e) => setCategorie(e.target.value)}
        />
        <input type="submit" value="Ajouter" />
      </form>
      <div className="box-to-lists-categorie">
        <span>Catégories : </span>
        <Categorie
          categories={categories}
          setCallGetCategoriesAgain={setCallGetCategoriesAgain}
          callGetCategoriesAgain={callGetCategoriesAgain}
        />
      </div>
    </StyledFormAddCategorie>
  );
};

export default FormAddCategorie;
const StyledFormAddCategorie = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  h1 {
    font-size: 1.5em;
  }
  form {
    padding: 10px;
    width: 50%;
    border: solid 2px white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  form > input {
    width: 100%;
    outline: none;
    border: none;
    padding: 5px;
    border-radius: 5px;
    font-size: 1.1em;
  }
  form > input:last-child {
    margin-top: 10px;
    width: 50%;
    background: #bab5b5;
    box-shadow: 7px 7px 15px #979393, -7px -7px 15px #ddd7d7;
  }
  form > input:last-child:active {
    background: #bab5b5;
    box-shadow: inset 7px 7px 15px #979393, inset -7px -7px 15px #ddd7d7;
  }
  .box-to-lists-categorie {
    margin-top: 10px;
    width: 50%;
    display: flex;
    flex-direction: column;
  }
  .box-to-lists-categorie > span {
    width: 100%;
    font-size: 0.8em;
  }

  //width >= 425px
  @media screen and (max-width: 429px) {
    margin-bottom: 20px;
    border-bottom: solid 1px white;
    h1 {
      font-size: 1em;
      margin-top: 5px;
    }
    form,
    .box-to-lists-categorie {
      width: 90%;
    }
  }
  //width =< 425px
  @media screen and (min-width: 425px) {
  }
`;
