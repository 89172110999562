import React, { useRef, useState } from "react";
import styled from "styled-components";
import { COLORS } from "../../../../utils/Style/styleGlobale";
import { Dynamic } from "../../../../Context/ContextDynamic/ContextDynamic";
import { toast } from "react-toastify";
import axios from "axios";

const DeleteAccount = () => {
  const [deleteAccount, setDeleteAccount] = useState(false);
  const [password, setPassword] = useState("");
  // Référence pour stocker l'ID du setTimeout
  const timeoutRef = useRef(null);
  const { user, logOutWhenDeleteAccount } = Dynamic();

  const inputPasswordBox = () => {
    const handleDeletAccount = async (e) => {
      e.preventDefault();
      if (!password) {
        return toast.error("Un mot de passe est requis");
      }
      try {
        const res = await axios({
          method: "delete",
          url: `${process.env.REACT_APP_API}user/delete/${user._id}`,
          withCredentials: true,
          data: { email: user.email, password },
        });
        if (res.data.error) return toast.error(res.data.error);
        if (res.data.success) {
          // Nettoyer le timeout précédent
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
          }
          // Définir un nouveau timeout et stocker sa référence
          timeoutRef.current = setTimeout(() => {
            logOutWhenDeleteAccount();
          }, 2000);
          return toast.success(res.data.success);
        }
      } catch (error) {
        console.log(error);
        return toast.error(
          "Une erreur est survenue lors de la suppression de votre compte."
        );
      }
    };

    return (
      <form
        className="box-textarea-delete-account"
        onSubmit={(e) => handleDeletAccount(e)}
      >
        <strong>Email : {user && user.email}</strong>
        <input
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Mot de passe"
        />
        <input
          type="submit"
          value="Supprimer mon compte"
          className="input-sub-delete-account"
        />
      </form>
    );
  };
  return (
    <StyledDeleteAccount>
      <span onClick={() => setDeleteAccount(!deleteAccount)}>
        {deleteAccount ? "Annuler" : "Supprimer mon compte"}
      </span>
      {deleteAccount && inputPasswordBox()}
    </StyledDeleteAccount>
  );
};

export default DeleteAccount;
const StyledDeleteAccount = styled.div`
  /* margin-top: 50px; */
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  span {
    font-size: 0.5em;
    letter-spacing: 0.17em;
    color: ${COLORS.red};
    font-weight: 900;
    padding: 5px;
    border-radius: 5px;
    background: #bab5b5;
    box-shadow: 7px 7px 15px #979393, -7px -7px 15px #ddd7d7;
    margin-bottom: 10px;
  }
  span:active {
    box-shadow: inset 10px 10px 20px #777777, inset -10px -10px 20px #9d9d9d;
  }
  .box-textarea-delete-account {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    input {
      padding: 3px;
      border-radius: 5px;
      outline: none;
      border: none;
      margin: 5px 0px;
    }
    .input-sub-delete-account {
      background: #bab5b5;
      box-shadow: 7px 7px 15px #979393, -7px -7px 15px #ddd7d7;
      color: ${COLORS.red};
    }
    .input-sub-delete-account:active {
      box-shadow: inset 10px 10px 20px #777777, inset -10px -10px 20px #9d9d9d;
    }
  }
`;
