import React, { useEffect } from "react";
import styled from "styled-components";
import { Dynamic } from "../../Context/ContextDynamic/ContextDynamic";
import SelectTable from "../../Components/SelectTable/SelectTable";
import { OrderCurrent } from "../../Context/ContextOrderCurrent/ContextOrderCurrent";

const PopToChoose = () => {
  const { popTableChoose, setPopTableChoose } = Dynamic();
  const { tableCurrent } = OrderCurrent();
  const closePop = () => {
    setPopTableChoose(false);
  };

  const whoDisplay = () => {
    if (popTableChoose) return <SelectTable cancelAction={closePop} />;
  };
  useEffect(() => {
    whoDisplay();
  }, []);

  return (
    <StyledPopToChoose>
      <div
        className={
          tableCurrent === 0
            ? "content-pop-to-choose"
            : "content-pop-to-choose-other"
        }
      >
        {whoDisplay()}
      </div>
    </StyledPopToChoose>
  );
};

export default PopToChoose;

const StyledPopToChoose = styled.div`
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0px;
  z-index: 50;
  background: #8080805c;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  .content-pop-to-choose {
    width: 50%;
    padding: 10px;
  }
  .content-pop-to-choose-other {
    padding: 10px;
    width: 90%;
    height: 80%;
    /* background: orange; */
  }
`;
