import React, { useState } from "react";
import { Dynamic } from "../../../../Context/ContextDynamic/ContextDynamic";
import styled from "styled-components";
import { IoMdAddCircle } from "react-icons/io";
import { COLORS } from "../../../../utils/Style/styleGlobale";
import { toast } from "react-toastify";
import axios from "axios";
import { FaFacebook } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";

const FormLinks = () => {
  const [categorieSocial, setCategorieSocial] = useState("");
  const [newSocial, setNewSocial] = useState("");
  const { user, setUser } = Dynamic();

  const returnLinksCatch = () => {
    const deleteOne = async (reseau, link) => {
      if (window.confirm("Supprimer ce réseau ? OK pour supprimer")) {
        const vide = 1;
        try {
          const res = await axios({
            method: "put",
            url: `${process.env.REACT_APP_API}user/update/${reseau}/${user._id}`,
            withCredentials: true,
            data: { vide },
          });
          if (res.data.user) {
            return setUser(res.data.user);
          }
        } catch (error) {
          console.log(error);
          return toast.error(
            "Une erreur est survenue lors de la suppression du réseau : " + link
          );
        }
      }
    };
    let text;
    if (user.facebook && !user.instagram) {
      text = "Votre réseau";
    } else {
      text = "Vos réseaux";
    }

    if (user.facebook || user.instagram) {
      return (
        <div className="list-links">
          <span>{text} : </span>
          <ul>
            {user.facebook && (
              <li onClick={() => deleteOne("facebook", user.facebook)}>
                <FaFacebook className="i-reseau-add" />
                {user.facebook}
              </li>
            )}
            {user.instagram && (
              <li onClick={() => deleteOne("instagram", user.instagram)}>
                {" "}
                <RiInstagramFill className="i-reseau-add" /> {user.instagram}
              </li>
            )}
          </ul>
        </div>
      );
    }
  };

  const facebookAdd = async (facebook) => {
    try {
      const res = await axios({
        method: "put",
        url: `${process.env.REACT_APP_API}user/update/facebook/${user._id}`,
        withCredentials: true,
        data: { facebook },
      });
      if (res.data.user) {
        setUser(res.data.user);
        setNewSocial("");
      }
    } catch (error) {
      console.log(error);
      return toast.error("Une erreur est survenue");
    }
  };
  const instagramAdd = async (instagram) => {
    try {
      const res = await axios({
        method: "put",
        url: `${process.env.REACT_APP_API}user/update/instagram/${user._id}`,
        withCredentials: true,
        data: { instagram },
      });

      if (res.data.user) {
        setUser(res.data.user);
        setNewSocial("");
      }
    } catch (error) {
      console.log(error);
      return toast.error("Une erreur est survenue");
    }
  };

  const subNameSocials = async (e) => {
    e.preventDefault();
    if (!newSocial || !categorieSocial)
      return toast.error(
        "Ajouter un nom et une catégorie d'un de vos réseaux sociaux"
      );
    if (categorieSocial === "facebook") {
      facebookAdd(newSocial);
    }
    if (categorieSocial === "instagram") {
      instagramAdd(newSocial);
    }
  };
  return (
    <StyledFormLinks>
      {returnLinksCatch()}
      <form className="form-Links" onSubmit={(e) => subNameSocials(e)}>
        <h3>Ajouter un réseau</h3>
        <select onChange={(e) => setCategorieSocial(e.target.value)}>
          <option value="">--Choisir un réseau --</option>
          <option value="facebook">Facebook</option>
          <option value="instagram">Instagram</option>
        </select>
        <input
          type="text"
          placeholder="Nouveau réseau"
          value={newSocial}
          onChange={(e) => setNewSocial(e.target.value)}
        />
        <IoMdAddCircle
          onClick={(e) => subNameSocials(e)}
          className="icon-send-link"
        />
      </form>
    </StyledFormLinks>
  );
};

export default FormLinks;

const StyledFormLinks = styled.div`
  /* background: blue; */
  /* padding: 10px; */
  .list-links {
    span {
      font-size: 0.8em;
    }
    ul {
      display: flex;
      li {
        background: ${COLORS.green};
        padding: 5px;
        margin: 0px 10px;
        font-size: 0.8em;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .i-reseau-add {
          margin-right: 5px;
        }
      }
    }
  }
  .form-Links {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 70%;
    margin: 10px auto;
    h3 {
      font-size: 0.9em;
      text-align: center;
    }
    select {
      margin: 5px auto;
      width: 100%;
      width: 60%;
      padding: 3px;
      border: none;
      outline: none;
      border-radius: 5px;
      text-align: center;
      cursor: pointer;
    }
    input {
      width: 100%;
      margin: 0px auto;
      padding: 3px;
      border: none;
      outline: none;
      border-radius: 5px;
    }
    .icon-send-link {
      position: absolute;
      top: 0px;
      right: 0px;
      /* color: ${COLORS.green}; */
      color: white;
      padding: 5px;
      font-size: 1.5em;
      background: ${COLORS.blackgrey};
      border-radius: 5px;
      cursor: pointer;
    }
  }
`;
