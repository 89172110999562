import { createContext, useContext, useEffect, useState } from "react";
import { io } from "socket.io-client";

const ContextSocket = createContext();
export const ContextSocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  useEffect(() => {
    // Créer la connexion du socket lors du montage du composant
    const socketInstance = io.connect(`${process.env.REACT_APP_API}`);
    setSocket(socketInstance);

    // Nettoyer la connexion du socket lors du démontage du composant
    return () => {
      socketInstance.disconnect();
    };
  }, []);
  return (
    <ContextSocket.Provider value={{ socket, setSocket }}>
      {children}
    </ContextSocket.Provider>
  );
};

export const SocketContext = () => {
  return useContext(ContextSocket);
};
