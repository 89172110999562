import React, { useEffect } from "react";
import styled from "styled-components";
import { COLORS } from "../Style/styleGlobale";

const ButtonNav = ({ text, actionClick, css, color }) => {
  return (
    <StyledButtonNav onClick={actionClick} $css={css} $color={color}>
      {text}
    </StyledButtonNav>
  );
};

export default ButtonNav;

const StyledButtonNav = styled.button`
  width: ${({ $css }) => `${$css}`}%;
  background: ${({ $color }) => ($color ? COLORS.red : COLORS.btnColor)};
  padding: 3px;
  outline: none;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-size: 1em;
`;
