import axios from "axios";
import React, { useEffect, useState } from "react";
import { ImSwitch } from "react-icons/im";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import styled from "styled-components";
import { COLORS } from "../../../../utils/Style/styleGlobale";
import { Data } from "../../../../Context/ContextData/ContextData";

const MenusLists = () => {
  const [menusData, setMenusData] = useState([]);
  const { getAllMenusExist } = Data();
  const getAllMenus = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API}menu/all/admin`,
        withCredentials: true,
      });
      // console.log(res);
      if (res.data && res.data.length > 0) {
        setMenusData(res.data);
      }
    } catch (error) {
      console.log(error);
      return toast.error(
        "Une erreur est survenue lors du chargement des menus"
      );
    }
  };
  const deleteMenu = async (id) => {
    if (!id) return toast.error("Identification du produit impossible");
    try {
      const res = await axios({
        method: "delete",
        url: `${process.env.REACT_APP_API}menu/delete/${id}`,
        withCredentials: true,
      });
      // console.log(res);
      if (res.data.succes) {
        getAllMenus();
        getAllMenusExist(); //to url : /order
        return toast.success(res.data.succes);
      }
      if (res.data.error) return toast.error(res.data.error);
    } catch (error) {
      console.log(error);
      return toast.error("Une erreur est survenue");
    }
  };
  const actifOrNot = async (id) => {
    if (!id) return toast.error("Identification du produit impossible");
    try {
      const res = await axios({
        method: "put",
        url: `${process.env.REACT_APP_API}menu/update/${id}`,
        withCredentials: true,
        data: { activ: 1 },
      });
      // console.log(res);
      if (res.data.error) return toast.error(res.data.error);
      if (res.data.succes) {
        getAllMenus();
        getAllMenusExist(); // to url : /order
        return toast.success(res.data.succes);
      }
    } catch (error) {
      console.log(error);
      return toast.error("Une erreur est survenue lors de mise-à-jour");
    }
  };
  useEffect(() => {
    getAllMenus();
  }, []);
  return (
    <StyledMenusLists>
      <div className="box-sous-parent-menus-lists">
        <h2>Menus</h2>
        <ul className="ul-menus-list">
          {menusData && menusData.length > 0 ? (
            menusData.map((menu) => (
              <div
                key={menu._id}
                className={
                  menu.status !== 0 ? " actif-menu" : "child-box-ul-menus-lits "
                }
              >
                <div className="box-to-li-to-info-menu">
                  <li>
                    {" "}
                    <strong>Menu : </strong> {menu.nameMenu}
                  </li>
                  <li>
                    {" "}
                    <strong>Description : </strong> {menu.infos}{" "}
                  </li>
                  <li>
                    {" "}
                    <strong>Prix : </strong>
                    {menu.price.toFixed(2)} €{" "}
                  </li>
                  <li className={menu.status !== 1 ? "no-actif" : ""}>
                    <strong>Statut : </strong>
                    {menu.status === 0 ? "Non actif 🤧" : "Actif 🔥"}
                  </li>
                </div>
                <div className="action-on-menu-select">
                  <MdDelete
                    className={
                      menu.status === 0
                        ? "btn-delete-menu-admin-not-actif"
                        : "btn-delete-menu-admin"
                    }
                    onClick={() => deleteMenu(menu._id)}
                  />
                  <ImSwitch
                    className={
                      menu.status === 0
                        ? "no-switch-actif"
                        : "actif-menu-switch"
                    }
                    onClick={() => actifOrNot(menu._id)}
                  />
                </div>
              </div>
            ))
          ) : (
            <li>Aucun menu</li>
          )}
        </ul>
      </div>
    </StyledMenusLists>
  );
};

export default MenusLists;
const StyledMenusLists = styled.div`
  padding: 5px;
  .box-sous-parent-menus-lists {
    display: flex;
    height: 75vh;
    flex-wrap: wrap;
    justify-content: flex-end;
    overflow-y: scroll;
  }
  .box-sous-parent-menus-lists > h2 {
    width: 100%;
    text-align: center;
  }
  .box-sous-parent-menus-lists > .ul-menus-list {
    width: 70%;
  }
  .box-sous-parent-menus-lists > .ul-menus-list > .child-box-ul-menus-lits {
    background: ${COLORS.blackgrey};
    display: flex;
    justify-content: space-between;
    /* flex-direction: column; */
    border-bottom: solid 2px white;
    border-right: solid 2px white;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 5px;
  }
  .box-sous-parent-menus-lists > .ul-menus-list > .actif-menu {
    display: flex;
    justify-content: space-between;
    /* flex-direction: column; */
    border-bottom: solid 2px ${COLORS.blackgrey};
    border-right: solid 2px ${COLORS.blackgrey};
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 5px;
    background: ${COLORS.green};
  }
  .box-sous-parent-menus-lists > .ul-menus-list > .box-to-li-to-info-menu,
  .box-sous-parent-menus-lists
    > .ul-menus-list
    > .actif-menu
    > .box-to-li-to-info-menu {
    display: flex;
    flex-direction: column;
  }
  .action-on-menu-select {
    width: 20%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .action-on-menu-select > .btn-delete-menu-admin {
    font-size: 1.9em;
    color: ${COLORS.red};
    border-radius: 50px;
    padding: 5px;
    box-shadow: 7px 7px 14px #03bf6e, -7px -7px 14px #03f38c;
  }
  .action-on-menu-select > .btn-delete-menu-admin:active {
    box-shadow: inset 7px 7px 14px #03bf6e, inset -7px -7px 14px #03f38c;
  }
  .action-on-menu-select > .btn-delete-menu-admin-not-actif {
    font-size: 1.8em;
    color: ${COLORS.red};
    border-radius: 50px;
    padding: 5px;
    box-shadow: 7px 7px 14px #5b5959, -7px -7px 14px #737171;
  }
  .action-on-menu-select > .btn-delete-menu-admin-not-actif:active {
    box-shadow: inset 7px 7px 14px #5b5959, inset -7px -7px 14px #737171;
  }
  .action-on-menu-select > .no-switch-actif {
    color: ${COLORS.green};
    font-size: 2em;
    border-radius: 50px;
    padding: 5px;
    box-shadow: 7px 7px 14px #5b5959, -7px -7px 14px #737171;
  }
  .action-on-menu-select > .no-switch-actif:active {
    box-shadow: inset 7px 7px 14px #5b5959, inset -7px -7px 14px #737171;
  }
  .action-on-menu-select > .actif-menu-switch {
    font-size: 1.9em;
    padding: 5px;
    border-radius: 50px;
    color: ${COLORS.red};
    box-shadow: 7px 7px 14px #03bf6e, -7px -7px 14px #03f38c;
  }
  .action-on-menu-select > .actif-menu-switch:active {
    box-shadow: inset 7px 7px 14px #03bf6e, inset -7px -7px 14px #03f38c;
  }
  .no-actif {
    color: ${COLORS.red};
  }
`;
