import React from "react";
import styled from "styled-components";

const PolitiqueConfidentiality = ({ title }) => {
  return (
    <StyledPolitiqueConfidentiality>
      <h1>{title}</h1>
      <p>
        La confidentialité de nos utilisateurs est d'une importance capitale
        pour nous chez NouLink. Cette politique de confidentialité décrit les
        types d'informations que nous collectons, comment nous les utilisons et
        les protégeons.
      </p>
      <ul>
        <h2>1 - Collecte de Données</h2>
        <li>
          Nous collectons le nom du restaurant et une adresse e-mail lorsque
          l'utilisateur crée un compte sur NouLink.
        </li>
        <li>
          Les données entrées par l'utilisateur, telles que les menus et les
          produits, sont également enregistrées dans notre système pour
          permettre la prise de commande.
        </li>
      </ul>
      <ul>
        <h2>2 - Utilisation des Données</h2>
        <li>
          Les informations collectées sont utilisées pour fournir nos services
          de prise de commande aux restaurants et à leurs clients.
        </li>
        <li>
          Nous pouvons également utiliser les données pour améliorer et
          personnaliser l'expérience de l'utilisateur sur notre plateforme.
        </li>
      </ul>
      <ul>
        <h2>3 - Protection des Données</h2>
        <li>
          Nous prenons des mesures techniques et organisationnelles pour
          protéger les données de nos utilisateurs contre tout accès non
          autorisé ou toute divulgation.
        </li>
      </ul>
      <ul>
        <h2>4 - Utilisation des Cookies</h2>
        <li>
          Nous utilisons des cookies pour faciliter la connexion des
          utilisateurs et pour enregistrer leur consentement aux conditions
          d'utilisation.
        </li>
        <li>
          Les cookies nous aident également à analyser et à améliorer la
          performance de notre application.
        </li>
      </ul>
      <ul>
        <h2>5 - Partage des Données</h2>
        <li>
          Nous ne partageons pas les informations personnelles des utilisateurs
          avec des tiers sans leur consentement, sauf si cela est nécessaire
          pour fournir nos services ou si cela est requis par la loi.
        </li>
      </ul>
    </StyledPolitiqueConfidentiality>
  );
};

export default PolitiqueConfidentiality;
const StyledPolitiqueConfidentiality = styled.div`
  //width >= 425px
  @media screen and (max-width: 425px) {
    h1 {
      font-size: 1.3em;
    }
    h2 {
      font-size: 1.1em;
    }
    p,
    ul > li {
      font-size: 0.9em;
    }
  }
  //width =< 425px
  @media screen and (min-width: 425px) {
    h1 {
      font-size: 1.3em;
    }
    h2 {
      font-size: 1.1em;
    }
    p,
    ul > li {
      font-size: 0.9em;
    }
  }
  //width =< 360px
  @media screen and (min-width: 360px) {
    h1 {
      font-size: 1.3em;
    }
    h2 {
      font-size: 1.1em;
    }
    p,
    ul > li {
      font-size: 0.9em;
    }
  }
`;
