import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Password from "../Update/Password";
import DeleteAccount from "../DeleteAccount/DeleteAccount";
import { MdCancel, MdManageAccounts } from "react-icons/md";
import { COLORS } from "../../../../utils/Style/styleGlobale";
import { FaEdit, FaFacebook } from "react-icons/fa";
import { Dynamic } from "../../../../Context/ContextDynamic/ContextDynamic";
import FormDescription from "../Forms/FormDescription";
import { IoCopy } from "react-icons/io5";
import FormLinks from "../Forms/FormLinks";
import { RiInstagramFill } from "react-icons/ri";
import { toast } from "react-toastify";
import { MdDeliveryDining } from "react-icons/md";
import { BsToggle2Off } from "react-icons/bs";
import { BsToggle2On } from "react-icons/bs";
import { FaRegCreditCard } from "react-icons/fa";
import { FaBell } from "react-icons/fa";
import { CiMoneyCheck1 } from "react-icons/ci";
import axios from "axios";
import { Data } from "../../../../Context/ContextData/ContextData";

export const Profil = () => {
  const [passwordUpdate, setPasswordUpdate] = useState(false);
  const [descriptionUpdate, setDescriptionUpdate] = useState(false);
  const [linksUpdate, setLinksUpdate] = useState(false);
  const { user, setUser } = Dynamic();
  const { alertNewOrder } = Data();

  const returnLinksCatch = () => {
    const copyReseau = (link) => {
      navigator.clipboard
        .writeText(link)
        .then(() => {
          return toast.success(`${link} est copié`);
        })
        .catch((error) => {
          console.log(error);
          return toast.error("Une erreur est survenue lors de la copie");
        });
    };
    if (user.facebook || user.instagram) {
      return (
        <ul>
          {user.facebook && (
            <li onClick={() => copyReseau(user.facebook)}>
              {" "}
              <FaFacebook className="i-reseau-add facebook" />
              {user.facebook} <IoCopy className="i-sign-copy" />
            </li>
          )}
          {user.instagram && (
            <li onClick={() => copyReseau(user.instagram)}>
              {" "}
              <RiInstagramFill className="i-reseau-add instagram" />
              {user.instagram} <IoCopy className="i-sign-copy" />
            </li>
          )}
        </ul>
      );
    } else {
      return <span>Aucun réseau</span>;
    }
  };

  const returnPasswordOrOther = () => {
    const actifDelivery = async (action) => {
      let status;
      if (action === "on") {
        status = 1;
      } else {
        status = 0;
      }
      try {
        const res = await axios({
          method: "put",
          url: `${process.env.REACT_APP_API}user/update/delivery/${user._id}`,
          withCredentials: true,
          data: { delivery: status },
        });
        if (res.data.user) {
          setUser(res.data.user);
          let textNotif = status === 1 ? "activée" : "désactivée";
          return toast.success(`La livraison est ${textNotif}`);
        }
      } catch (error) {
        console.log(error);
        return toast.error("Une erreur est survenue");
      }
    };
    const actifCreditCard = async (action) => {
      let status;
      if (action === "on") {
        status = 1;
      } else {
        status = 0;
      }
      try {
        const res = await axios({
          method: "put",
          url: `${process.env.REACT_APP_API}user/update/creditcard/${user._id}`,
          withCredentials: true,
          data: { creditcard: status },
        });
        if (res.data.user) {
          setUser(res.data.user);
          let textNotif = status === 1 ? "activée" : "désactivée";
          return toast.success(`Le règlement par CB est ${textNotif}`);
        }
      } catch (error) {
        console.log(error);
        return toast.error("Une erreur est survenue");
      }
    };
    const actifCheque = async (action) => {
      let status;
      if (action === "on") {
        status = 1;
      } else {
        status = 0;
      }
      try {
        const res = await axios({
          method: "put",
          url: `${process.env.REACT_APP_API}user/update/cheque/${user._id}`,
          withCredentials: true,
          data: { cheque: status },
        });
        if (res.data.user) {
          setUser(res.data.user);
          let textNotif = status === 1 ? "activé" : "désactivé";
          return toast.success(`Le règlement par chèque est ${textNotif}`);
        }
      } catch (error) {
        console.log(error);
        return toast.error("Une erreur est survenue");
      }
    };

    const actifSon = async (action) => {
      let status;
      if (action === "on") {
        status = 1;
      } else {
        status = 0;
      }
      try {
        const res = await axios({
          method: "put",
          url: `${process.env.REACT_APP_API}user/update/son/${user._id}`,
          withCredentials: true,
          data: { notificationson: status },
        });
        if (res.data.user) {
          setUser(res.data.user);
          let textNotif = status === 1 ? "activé" : "désactivé";
          alertNewOrder();
          return toast.success(
            `Son pour chaque commande entrante est ${textNotif}`
          );
        }
      } catch (error) {
        console.log(error);
        return toast.error("Une erreur est survenue");
      }
    };
    return (
      <div className="card-second-parent">
        <div className="info-if-delivery">
          <div className="icon-plus-info">
            <MdDeliveryDining className="icon-delivery" />
            <span>Livraison</span>
          </div>
          <div className="action-to-actif-delivery">
            {!user.delivery || user.delivery === 0 ? (
              <BsToggle2Off
                className="btn-delivery-i off"
                onClick={() => actifDelivery("on")}
              />
            ) : (
              <BsToggle2On
                className="btn-delivery-i on"
                onClick={() => actifDelivery("off")}
              />
            )}
          </div>
        </div>
        <div className="info-if-delivery">
          <div className="icon-plus-info">
            <FaRegCreditCard className="icon-delivery" />
            <span>CB</span>
          </div>
          <div className="action-to-actif-creditcard">
            {!user.creditcard || user.creditcard === 0 ? (
              <BsToggle2Off
                className="btn-creditcard-i off"
                onClick={() => actifCreditCard("on")}
              />
            ) : (
              <BsToggle2On
                className="btn-creditcard-i on"
                onClick={() => actifCreditCard("off")}
              />
            )}
          </div>
        </div>
        <div className="info-if-delivery">
          <div className="icon-plus-info">
            <CiMoneyCheck1 className="icon-delivery" />
            <span>Chèque</span>
          </div>
          <div className="action-to-actif-cheque">
            {!user.cheque || user.cheque === 0 ? (
              <BsToggle2Off
                className="btn-cheque-i off"
                onClick={() => actifCheque("on")}
              />
            ) : (
              <BsToggle2On
                className="btn-cheque-i on"
                onClick={() => actifCheque("off")}
              />
            )}
          </div>
        </div>
        <div className="info-if-delivery">
          <div className="icon-plus-info">
            <FaBell className="icon-delivery" />
            <span>Son commande entrante</span>
          </div>
          <div className="action-to-actif-cheque">
            {!user.notificationson || user.notificationson === 0 ? (
              <BsToggle2Off
                className="btn-cheque-i off"
                onClick={() => actifSon("on")}
              />
            ) : (
              <BsToggle2On
                className="btn-cheque-i on"
                onClick={() => actifSon("off")}
              />
            )}
          </div>
        </div>
        <div className="description-and-other">
          <div className="description-infos">
            {descriptionUpdate ? (
              <FormDescription setDescriptionUpdate={setDescriptionUpdate} />
            ) : (
              <>
                <strong>Description : </strong>
                <p className={!user.description ? "opa-none" : ""}>
                  {user.description
                    ? user.description
                    : "Ajouter un message, une description, (Numéro, lieu, horaire etc...)"}
                </p>
              </>
            )}
          </div>
          {descriptionUpdate ? (
            <MdCancel
              className="icon-edit red"
              onClick={() => setDescriptionUpdate(!descriptionUpdate)}
            />
          ) : (
            <FaEdit
              className="icon-edit"
              onClick={() => setDescriptionUpdate(!descriptionUpdate)}
            />
          )}
        </div>
        <div className="the-links">
          {linksUpdate ? (
            <FormLinks />
          ) : (
            <div className="info-links">
              <strong>Réseaux : </strong>
              {returnLinksCatch()}
            </div>
          )}

          {linksUpdate ? (
            <MdCancel
              className="icon-edit red"
              onClick={() => setLinksUpdate(!linksUpdate)}
            />
          ) : (
            <FaEdit
              className="icon-edit"
              onClick={() => setLinksUpdate(!linksUpdate)}
            />
          )}
        </div>
      </div>
    );
  };
  return (
    <StyledProfil>
      <h2>Mon profil</h2>
      <DeleteAccount />
      <MdManageAccounts className="icon-profil" />
      <div className="threeth-to-password">
        <div className="update-password">
          <span
            className="updat-password"
            onClick={() => setPasswordUpdate(!passwordUpdate)}
          >
            {passwordUpdate ? "Retour" : "Changer mot de passe"}
          </span>
          {passwordUpdate ? <Password /> : returnPasswordOrOther()}
        </div>
      </div>
    </StyledProfil>
  );
};

const StyledProfil = styled.div`
  height: 65vh;
  width: 70%;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  h2 {
    text-align: center;
    font-size: 1em;
  }
  .icon-profil {
    font-size: 3em;
    padding: 5px;
    display: block;
    margin: 0px auto;
    /* color: ${COLORS.blackgrey}; */
    color: white;
    /* border: solid 3px ${COLORS.green}; */
    border: solid 3px white;
    border-radius: 50%;
  }
  /* .card-second-parent, */
  .threeth-to-password {
    width: 60%;
    /* padding: 5px; */
    margin: 0px auto;
    /* background: orange; */
  }
  .card-second-parent {
    width: 60%;
    /* padding: 5px; */
    height: 100%;
    margin: 0px auto;
  }

  .card-second-parent > .info-if-delivery {
    display: flex;
    justify-content: space-between;
    .icon-plus-info {
      display: flex;
      align-items: center;
    }
    .icon-delivery {
      font-size: 1.5em;
      margin-right: 5px;
    }
    .action-to-actif-delivery > .btn-delivery-i,
    .action-to-actif-creditcard > .btn-creditcard-i,
    .action-to-actif-cheque > .btn-cheque-i {
      font-size: 1.5em;
      cursor: pointer;
    }
    .action-to-actif-delivery > .off {
      color: ${COLORS.red};
    }
    .action-to-actif-delivery > .on {
      color: ${COLORS.green};
    }
    .action-to-actif-creditcard > .off {
      color: ${COLORS.red};
    }
    .action-to-actif-creditcard > .on {
      color: ${COLORS.green};
    }
    .action-to-actif-cheque > .off {
      color: ${COLORS.red};
    }
    .action-to-actif-cheque > .on {
      color: ${COLORS.green};
    }
  }
  .card-second-parent > div {
    padding: 5px;
    position: relative;
    border-top: solid 2px ${COLORS.green};
  }
  .icon-edit {
    position: absolute;
    top: 0px;
    right: 5px;
    /* color: ${COLORS.green}; */
    color: white;
    padding: 5px;
    font-size: 1.5em;
    background: ${COLORS.blackgrey};
    border-radius: 5px;
    cursor: pointer;
  }
  .red {
    color: ${COLORS.red} !important;
  }
  .the-links {
    margin-top: 10px;
    .info-links {
      strong {
      }
      ul {
        display: flex;
        li {
          background: ${COLORS.green};
          padding: 5px;
          margin: 0px 10px;
          font-size: 0.8em;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          cursor: pointer;
          .i-reseau-add {
            margin-right: 5px;
          }
          .facebook {
            color: #1877f2;
          }
          .instagram {
            color: #833ab4;
          }
          .i-sign-copy {
            margin-left: 10px;
            /* background: ${COLORS.blackgrey}; */
            /* padding: 5px; */
            font-size: 1.5em;
            /* border-radius: 10px; */
            color: white;
          }
        }
      }
    }
  }
  .updat-password {
    color: ${COLORS.red};
    cursor: pointer;
    font-size: 0.7em;
  }
  .opa-none {
    opacity: 0.4;
  }

  //width >= 425px
  @media screen and (max-width: 429px) {
    margin-top: 10px;
    .threeth-to-password {
      width: 90%;
    }
    .card-second-parent {
      width: 100%;
    }
  }
`;
