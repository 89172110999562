import React from "react";
import styled from "styled-components";

const MentionsLegales = ({ title }) => {
  return (
    <StyledMentionsLegales>
      <h1>{title}</h1>
      <p>
        NouLink est une application web développée et exploitée par Lodrick.
        NouLink est destiné à être utilisé comme une plateforme de prise de
        commande pour les restaurants et les établissements similaires.
      </p>
      <ul>
        <h2>Coordonées :</h2>
        <li>Lodrick</li>
        <li>dev.frenchlod@gmail.com</li>
      </ul>
      <div>
        <h2>Responsabilité : </h2>
        <p>
          NouLink s'efforce de fournir des informations précises et à jour, mais
          ne peut garantir l'exactitude ou l'exhaustivité des informations
          fournies. L'utilisation de NouLink se fait aux risques et périls de
          l'utilisateur. NouLink ne peut être tenu responsable des dommages
          directs ou indirects résultant de l'utilisation de l'application.
        </p>
      </div>
      <div>
        <h2>Cookies :</h2>
        <p>
          NouLink utilise des cookies pour améliorer l'expérience utilisateur et
          fournir des fonctionnalités personnalisées. En utilisant NouLink, vous
          consentez à l'utilisation de cookies conformément à notre politique de
          confidentialité.
        </p>
      </div>
      <div>
        <h2>Modification des Mentions Légales :</h2>
        <p>
          NouLink se réserve le droit de modifier les présentes mentions légales
          à tout moment, sans préavis. Il est de votre responsabilité de
          consulter régulièrement cette page pour prendre connaissance des
          éventuelles modifications.
        </p>
      </div>
      <strong>
        Pour toute question ou réclamation concernant les présentes mentions
        légales, veuillez nous contacter à l'adresse e-mail mentionnée
        ci-dessus.
      </strong>
    </StyledMentionsLegales>
  );
};

export default MentionsLegales;
const StyledMentionsLegales = styled.div`
  //width >= 425px
  @media screen and (max-width: 425px) {
    h1 {
      font-size: 1.3em;
    }
    h2 {
      font-size: 1.1em;
    }
    p,
    ul > li {
      font-size: 0.9em;
    }
  }
  //width =< 425px
  @media screen and (min-width: 425px) {
    h1 {
      font-size: 1.3em;
    }
    h2 {
      font-size: 1.1em;
    }
    p,
    ul > li {
      font-size: 0.9em;
    }
  }
  //width =< 360px
  @media screen and (min-width: 360px) {
    h1 {
      font-size: 1.3em;
    }
    h2 {
      font-size: 1.1em;
    }
    p,
    ul > li {
      font-size: 0.9em;
    }
  }
`;
