import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ButtonNav from "../../utils/ButtonNav/ButtonNav";
import { Dynamic } from "../../Context/ContextDynamic/ContextDynamic";
import { OrderCurrent } from "../../Context/ContextOrderCurrent/ContextOrderCurrent";
import { MdAdd, MdCancel, MdCheckBox, MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import axios from "axios";
import { SocketContext } from "../../Context/ContextSocket/ContextSocket";
import { COLORS } from "../../utils/Style/styleGlobale";

const TableCurrent = () => {
  const [activeNoteCurrent, setActiveNoteCurrent] = useState(false);
  const [addNoteCurrent, setAddNoteCurrent] = useState("");
  const [totalOrderCurrent, setTotalOrderCurrent] = useState(0);
  const { user } = Dynamic();
  const { socket } = SocketContext();
  const {
    orderAllUser,
    setOrderAllUser,
    orderCurrentMenu,
    setOrderCurrentMenu,
    tableCurrent,
    setTableCurrent,
  } = OrderCurrent();
  const cancelOrderCurrent = () => {
    if (
      window.confirm(
        "Voulez-vous vraiment annuler cette commande ? Ok pour continuer"
      )
    ) {
      setTableCurrent(0);
      setOrderAllUser([]); //last array new
      setAddNoteCurrent("");
      setOrderCurrentMenu([]);
    }
  };

  //new model data
  //add note to order current
  const noteOrderCurrent = () => {
    const deleteNote = () => {
      setAddNoteCurrent("");
      setActiveNoteCurrent(false);
    };
    if (
      (orderAllUser && orderAllUser.length > 0) ||
      (orderCurrentMenu && orderCurrentMenu.length > 0)
    ) {
      return (
        <div className="box-to-add-note">
          {activeNoteCurrent ? (
            <div className="box-textarea-and-box-to-btns">
              <textarea
                onChange={(e) => setAddNoteCurrent(e.target.value)}
                value={addNoteCurrent ? addNoteCurrent : ""}
                placeholder="Ajouter une note..."
              ></textarea>
              <div className="box-to-btns-interaction-on-textarea">
                {" "}
                <MdCancel
                  onClick={() => setActiveNoteCurrent(false)}
                  className="cancel-note"
                />{" "}
                <MdCheckBox
                  onClick={() => setActiveNoteCurrent(false)}
                  className="valid-note"
                />{" "}
                {addNoteCurrent && (
                  <MdDelete
                    onClick={() => deleteNote()}
                    className="delete-note"
                  />
                )}
              </div>
            </div>
          ) : (
            <div
              onClick={() => setActiveNoteCurrent(true)}
              className="btn-to-add-active-textarea"
            >
              <span>Ajouter une note ? </span>
            </div>
          )}
        </div>
      );
    }
  };

  // Fonction pour mettre à jour orderAllUser en supprimant les index vides
  const updateOrderAllUser = () => {
    // console.log(orderAllUser);
    // Parcourir chaque élément de orderAllUser
    for (let i = orderAllUser.length - 1; i >= 0; i--) {
      const item = orderAllUser[i];
      const keys = Object.keys(item);

      // Vérifier si l'index est vide
      if (keys.length > 0 && item[keys[0]].length === 0) {
        // Supprimer l'index vide
        orderAllUser.splice(i, 1);
      }
    }
  };

  //new fonction to send in cookin
  const sendSelectOrders = async () => {
    // console.log(orderAllUser);
    // console.log(totalOrderCurrent);
    // return;
    updateOrderAllUser();
    // console.log(orderAllUser);
    if (!tableCurrent) return toast.error("Veuillez sélectionner une table");
    //send in data base
    if (orderCurrentMenu && orderCurrentMenu.length > 0) {
      const menu = { Menu: orderCurrentMenu };
      orderAllUser.unshift(menu);
    }
    try {
      const res = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API}order/add`,
        withCredentials: true,
        data: {
          order: orderAllUser,
          table: tableCurrent,
          note: addNoteCurrent,
          totalOrder: totalOrderCurrent,
        },
      });
      // console.log(res);
      if (res.data.succes) {
        toast.success(res.data.succes);
        socket.emit("new-order-add", { id: user._id });
        setTableCurrent(0);
        setOrderAllUser([]); //last array new
        setAddNoteCurrent("");
        setOrderCurrentMenu([]);
      }
    } catch (error) {
      console.log(error);
      return toast.error(
        "Une erreur est survenue lors de l'envoie de la commande"
      );
    }
  };
  //display btn send in cooking
  const displayBtnSendInCookin = () => {
    // if (orderAllUser && orderAllUser.length > 0) {
    if (
      (filteredOrders && filteredOrders.length > 0) ||
      (orderCurrentMenu && orderCurrentMenu.length > 0)
    ) {
      return (
        <ButtonNav
          text={"Envoyer en cuisine"}
          actionClick={() => sendSelectOrders()}
        />
      );
    }
  };
  // Fonction pour retirer un produit
  const removeProduct = (category, productName) => {
    const updatedOrders = orderAllUser.map((item) => {
      if (item[category]) {
        return {
          ...item,
          [category]: item[category].filter(
            (product) => product.name !== productName
          ),
        };
      }
      return item;
    });
    // Mettre à jour l'état avec les commandes mises à jour
    setOrderAllUser(updatedOrders);
  };
  // Filtrer les catégories vides
  const filteredOrders = orderAllUser.filter((item) => {
    return Object.values(item).some((products) => products.length > 0);
  });
  //conftion display
  const displayListOrdersCurrent = () => {
    // Fonction pour calculer le sous-total de chaque catégorie
    const calculateSubtotal = (category) => {
      let subtotal = 0;
      orderAllUser.forEach((item) => {
        if (item[category]) {
          item[category].forEach((product) => {
            subtotal += product.price;
          });
        }
      });
      // console.log(subtotal);
      return subtotal.toFixed(2);
    };
    return (
      <div className="parent-box-display-orders-current">
        {/* {orderAllUser.map((item, index) => ( */}
        {filteredOrders.map((item, index) => (
          <ul key={index} className="ul-to-display-orders-current">
            {Object.entries(item).map(([category, products]) => (
              <div key={category} className="child-box-display-orders-current">
                <h3 className="title-to-sous-total">
                  {category} - Sous-total: {calculateSubtotal(category)} €
                </h3>
                {products.map((product, index) => (
                  <li key={index} className="li-list-detail-orders">
                    <div className="detail-product-box">
                      <span>{product.name}</span>
                      <span>{product.note && product.note}</span>
                      <span>{product.price.toFixed(2)} €</span>
                    </div>
                    <MdDelete
                      onClick={() => removeProduct(category, product.name)}
                      className="btn-delete-one-product"
                    />
                  </li>
                ))}
              </div>
            ))}
          </ul>
        ))}
      </div>
    );
  };

  //fonction calcule total
  const calculateTotal = () => {
    let total = 0;
    orderCurrentMenu.forEach((item) => {
      total += item.price;
    });
    // console.log(orderAllUser);
    orderAllUser.forEach((item) => {
      Object.values(item).forEach((products) => {
        products.forEach((product) => {
          total += product.price;
        });
      });
    });
    return total;
  };

  //fonction display menu
  const displayListMenuIfMenuAdded = () => {
    const deleteMenu = (index) => {
      setOrderCurrentMenu((prev) => prev.filter((item, i) => i !== index));
    };
    if (orderCurrentMenu && orderCurrentMenu.length > 0) {
      const sousTo = orderCurrentMenu.reduce(
        (acc, menu) => acc + menu.price,
        0
      );

      return (
        <ul className="list-to-menu">
          <h3>Menu(s) - Sous-total : {sousTo.toFixed(2)}€</h3>
          {orderCurrentMenu.map((menu, index) => (
            <div key={index} className="child-ul-list-to-menu">
              <div className="box-info-menu-current">
                {/* <li>{menu.menu}</li> */}
                <li>{menu.name}</li>
                <li className="description">{menu.description}</li>
                <li>{menu.price.toFixed(2)} €</li>
              </div>
              <div className="box-delete-menu-current">
                <MdDelete
                  className="btn-delete-menu-current"
                  onClick={() => deleteMenu(index)}
                />
              </div>
            </div>
          ))}
        </ul>
      );
    }
  };
  useEffect(() => {
    setTotalOrderCurrent(calculateTotal());
  }, []);
  return (
    <StyledTableCurrent>
      <div className="header-table-current">
        <ButtonNav
          text={"Annuler cette commande"}
          actionClick={() => cancelOrderCurrent()}
          color="red"
        />
        {/* {displayBtnToSendCooking()} */}
        {displayBtnSendInCookin()}
      </div>
      {noteOrderCurrent()}
      <div className="info-table-current">
        <div className="table-and-total">
          <h2>Table : {tableCurrent}</h2>
          <h2 className="total-all">Total : {calculateTotal().toFixed(2)} €</h2>
        </div>
        {addNoteCurrent && <p>Note : {addNoteCurrent}</p>}
      </div>
      {orderAllUser && orderAllUser.length > 0 && displayListOrdersCurrent()}
      {displayListMenuIfMenuAdded()}
    </StyledTableCurrent>
  );
};

export default TableCurrent;

const StyledTableCurrent = styled.div`
  /* background: skyblue; */
  margin-top: 15px;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  .header-table-current {
    /* background: blue; */
    padding: 5px;
    display: flex;
    justify-content: space-between;
  }
  .box-to-add-note {
    /* background: violet; */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .box-to-add-note > .btn-to-add-active-textarea {
    padding: 5px;
    border-radius: 5px;
    background: #ffffff;
    box-shadow: 7px 7px 14px #f0f0f0, -7px -7px 14px #ffffff;
  }
  .box-to-add-note > .btn-to-add-active-textarea:active {
    background: #ffffff;
    box-shadow: inset 7px 7px 14px #f0f0f0, inset -7px -7px 14px #ffffff;
  }
  .box-to-add-note > .box-textarea-and-box-to-btns {
    /* background: orange; */
    padding: 5px;
    border-radius: 5px;
    height: 15vh;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: solid 1px ${COLORS.grey};
  }
  .box-to-add-note > .box-textarea-and-box-to-btns > textarea {
    outline: none;
    border: none;
    resize: none;
    height: 80%;
    border-bottom: solid 1px ${COLORS.grey};
  }
  .box-to-add-note
    > .box-textarea-and-box-to-btns
    > .box-to-btns-interaction-on-textarea {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
  }
  .box-to-add-note
    > .box-textarea-and-box-to-btns
    > .box-to-btns-interaction-on-textarea
    > .valid-note {
    color: ${COLORS.green};
    font-size: 1.2em;
  }
  .box-to-add-note
    > .box-textarea-and-box-to-btns
    > .box-to-btns-interaction-on-textarea
    > .cancel-note {
    color: orange;
    font-size: 1.2em;
  }
  .box-to-add-note
    > .box-textarea-and-box-to-btns
    > .box-to-btns-interaction-on-textarea
    > .delete-note {
    color: ${COLORS.red};
    font-size: 1.2em;
  }
  .info-table-current {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    padding: 10px;
  }
  .info-table-current > .table-and-total {
    display: flex;
    justify-content: space-between;
  }
  .info-table-current > .table-and-total > .total-all {
    background: ${COLORS.green};
    padding: 5px;
    border-radius: 10px;
  }
  .info-table-current > p {
    width: 100%;
    margin-top: 5px;
    background: orange;
    padding: 5px;
  }
  .parent-box-display-orders-current {
    /* background: pink; */
  }
  .parent-box-display-orders-current > .ul-to-display-orders-current {
    /* background: blue; */
    /* margin: 5px 0px; */
    margin-bottom: 5px;
  }
  .parent-box-display-orders-current
    > .ul-to-display-orders-current
    > .child-box-display-orders-current {
    /* background: orange; */
  }
  .parent-box-display-orders-current
    > .ul-to-display-orders-current
    > .child-box-display-orders-current
    > h3 {
    /* background: green; */
    background: ${COLORS.greenTransparent};
    margin-bottom: 5px;
    padding: 5px;
  }
  .parent-box-display-orders-current
    > .ul-to-display-orders-current
    > .child-box-display-orders-current
    > .li-list-detail-orders {
    display: flex;
    justify-content: space-between;
    border-bottom: dashed 1px ${COLORS.grey};
    margin-bottom: 5px;
  }
  .parent-box-display-orders-current
    > .ul-to-display-orders-current
    > .child-box-display-orders-current
    > .li-list-detail-orders
    > .detail-product-box {
    width: 90%;
    display: flex;
    justify-content: space-between;
  }
  .parent-box-display-orders-current
    > .ul-to-display-orders-current
    > .child-box-display-orders-current
    > .li-list-detail-orders
    > .btn-delete-one-product {
    width: 10%;
    font-size: 1.3em;
    color: ${COLORS.red};
  }
  ul {
    /* background: green; */
    height: 100%;
    padding: 10px;
  }
  .icon-delete-in-table-current {
    font-size: 1.5em;
    color: ${COLORS.red};
  }

  .delete-supple {
    font-size: 1.1em;
    color: red;
  }

  //list menu
  /* .list-to-menu {
    background: orange;
  } */
  .list-to-menu > h3 {
    background: ${COLORS.greenTransparent};
    padding: 5px 0px;
    text-align: center;
  }
  .list-to-menu > .child-ul-list-to-menu {
    /* background: white; */
    padding: 5px 0px;
    display: flex;
    border-bottom: dashed 1px ${COLORS.blackgrey};
    margin-bottom: 10px;
  }
  .list-to-menu > .child-ul-list-to-menu > .box-info-menu-current {
    width: 90%;
    display: flex;
    flex-direction: column;
  }
  .list-to-menu
    > .child-ul-list-to-menu
    > .box-info-menu-current
    > .description {
    width: 70%;
    overflow-y: scroll;
    margin: 5px 0px;
    /* background: white; */
  }
  .list-to-menu > .child-ul-list-to-menu > .box-delete-menu-current {
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    .btn-delete-menu-current {
      font-size: 1.3em;
      color: ${COLORS.red};
    }
  }
`;
