import React from "react";
import styled from "styled-components";

const Cgu = ({ title }) => {
  return (
    <StyledCgu>
      <h1>{title}</h1>
      <ul>
        <h2>1 - Acceptation des Conditions</h2>
        <li>
          En utilisant l'application NouLink, vous acceptez automatiquement nos
          conditions d'utilisation. Si vous n'acceptez pas ces conditions,
          veuillez ne pas utiliser notre application.
        </li>
      </ul>
      <ul>
        <h2>1-b - Modification</h2>
        <li>
          Les conditions d'utilisation, politique de confidentialité et les
          mentions légales peuvent être modifier a tout moment, sans préavis
        </li>
      </ul>
      <ul>
        <h2>2 - Gratuité de l'Application</h2>
        <li>
          NouLink est actuellement disponible gratuitement pour les restaurants
          et les utilisateurs. Cependant, nous nous réservons le droit de
          modifier nos tarifs à tout moment, sans préavis.
        </li>
      </ul>
      <ul>
        <h2>3 - Connexion Internet Sécurisée</h2>
        <li>
          Pour utiliser l'application NouLink, une connexion Internet sécurisée
          est requise. Nous ne recommandons pas l'utilisation de connexions
          ouvertes ou non sécurisées, où tout le monde peut se connecter sans
          mot de passe.(Connexion type cyberCafé)
        </li>
      </ul>
      <ul>
        <h2>4 - Interruption de Service</h2>
        <li>
          Les services NouLink peuvent être interrompus à tout moment, avec ou
          sans préavis. Nous nous efforçons de minimiser les interruptions, mais
          nous ne pouvons garantir une disponibilité continue de nos services.
        </li>
      </ul>
      <ul>
        <h2>5 - Sécurité des Utilisateurs</h2>
        <li>
          Nous faisons des efforts pour maximiser la sécurité des utilisateurs
          sur notre plateforme. Cependant, malgré nos meilleures pratiques en
          matière de sécurité, nous ne pouvons garantir une sécurité totale des
          données. Les utilisateurs sont encouragés à prendre des mesures pour
          protéger leurs informations personnelles.
        </li>
      </ul>
      <ul>
        <h2>6 - Utilisation des Données</h2>
        <li>
          Les données que vous fournissez à NouLink seront utilisées uniquement
          dans le but de fournir nos services de prise de commande et également
          pour améliorer nos services. Nous ne partagerons pas vos informations
          personnelles avec des tiers sans votre consentement.
        </li>
      </ul>
      <p>
        En utilisant NouLink, vous reconnaissez avoir lu et compris notre
        politique de confidentialité et nos conditions d'utilisation. Pour toute
        question ou préoccupation concernant notre politique de confidentialité
        ou nos conditions d'utilisation, veuillez nous contacter à
        dev.frenchlod@gmail.com
      </p>
    </StyledCgu>
  );
};

export default Cgu;

const StyledCgu = styled.div`
  //width >= 425px
  @media screen and (max-width: 425px) {
    h1 {
      font-size: 1.3em;
    }
    h2 {
      font-size: 1.1em;
    }
    p,
    ul > li {
      font-size: 0.9em;
    }
  }
  //width =< 425px
  @media screen and (min-width: 425px) {
    h1 {
      font-size: 1.3em;
    }
    h2 {
      font-size: 1.1em;
    }
    p,
    ul > li {
      font-size: 0.9em;
    }
  }
  //width =< 360px
  @media screen and (min-width: 360px) {
    h1 {
      font-size: 1.3em;
    }
    h2 {
      font-size: 1.1em;
    }
    p,
    ul > li {
      font-size: 0.9em;
    }
  }
`;
