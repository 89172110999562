import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS } from "../../../../utils/Style/styleGlobale";
import { MdClose } from "react-icons/md";
import axios from "axios";
import { toast } from "react-toastify";
import { Data } from "../../../../Context/ContextData/ContextData";

const UpdateProduct = ({ setUpdate, productUpdate, setProductUpdate }) => {
  //   console.log(productUpdate);
  const [id, setId] = useState(productUpdate._id);
  const [name, setName] = useState(productUpdate.nameProduct);
  const [price, setPrice] = useState(productUpdate.price);
  const [infos, setInfos] = useState(productUpdate.infos);
  const { getAllData } = Data();

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (!name || !price)
      return toast.error("Les champs Produit et Prix sont obligatoires");
    try {
      const res = await axios({
        method: "put",
        url: `${process.env.REACT_APP_API}product/update/${id}`,
        withCredentials: true,
        data: {
          id: productUpdate._id,
          name,
          price,
          infos,
          categorie: productUpdate.categorie,
        },
      });
      //   console.log(res);
      if (res.data.error) {
        return toast.error(res.data.error);
      }
      if (res.data.succes) {
        getAllData();
        setUpdate(false);
        return toast.success(res.data.succes);
      }
    } catch (error) {
      console.log(error);
      return toast.error(
        "Une erreur est survenue lors de la modification du produit"
      );
    }
  };
  return (
    <StyledUpdateProduct onSubmit={handleUpdate}>
      <MdClose className="icon-close" onClick={() => setUpdate(false)} />
      <div className="vision">
        <span>Produit : {productUpdate.nameProduct}</span>
        <span>Prix : {productUpdate.price.toFixed(2)}€</span>
        {productUpdate.infos && <span>{productUpdate.infos}</span>}
        <span>Id : {productUpdate._id}</span>
      </div>
      <div className="inpu-form">
        <input
          type="text"
          placeholder="Nom produit"
          value={name ? name : ""}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          type="text"
          placeholder="00.00"
          value={price ? price : ""}
          onChange={(e) => setPrice(e.target.value)}
        />
        <textarea
          placeholder="Donnez le choix au client(Optionnelle)"
          value={infos ? infos : ""}
          onChange={(e) => setInfos(e.target.value)}
        ></textarea>
        <input type="submit" value="Modifier" />
      </div>
    </StyledUpdateProduct>
  );
};

export default UpdateProduct;

const StyledUpdateProduct = styled.form`
  background: ${COLORS.greenTransparent};
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  .icon-close {
    font-size: 1.8em;
    color: white;
    background: ${COLORS.red};
    padding: 3px;
    border-radius: 10px;
    cursor: pointer;
  }
  .vision {
    display: flex;
    flex-direction: column;
    margin: 10px;
    background: ${COLORS.grey};
    width: 50%;
    padding: 5px;
    border-radius: 3px;
    span {
      margin: 5px 0px 0px 0px;
      color: ${COLORS.green};
      /* color: white; */
    }
  }
  .inpu-form {
    background: ${COLORS.green};
    width: 60%;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    input,
    textarea {
      margin-top: 10px;
      outline: none;
      padding: 3px;
      font-size: 1.1em;
      border: none;
      border-radius: 3px;
    }
    input:last-child {
      background: ${COLORS.btnColor};
      color: white;
      cursor: pointer;
    }
    textarea {
      resize: none;
      height: 10vh;
    }
  }
  //width >= 425px
  @media screen and (max-width: 429px) {
    .vision,
    .inpu-form {
      width: 80%;
    }
  }
`;
