import React, { useState } from "react";
import styled from "styled-components";
import { COLORS } from "../../../../utils/Style/styleGlobale";
import { toast } from "react-toastify";
import axios from "axios";

const FormAddMenu = () => {
  const [menu, setMenu] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const sendForm = async (e) => {
    e.preventDefault();
    if (!menu || !description || !price)
      return toast.error("Les champs sont nécessaires");
    //check if a good format to price
    if (/^\d*\.?\d*$/.test(price)) {
      try {
        const res = await axios({
          method: "post",
          url: `${process.env.REACT_APP_API}menu/add`,
          withCredentials: true,
          data: {
            name: menu,
            info: description,
            price,
          },
        });
        console.log(res);
        if (res.data.succes) {
          toast.success(res.data.succes);
          setMenu("");
          setDescription("");
          setPrice("");
        }
        if (res.data.error) return toast.error(res.data.error);
      } catch (error) {
        console.log(error);
        return toast.error("Une erreur est survenue");
      }
    } else {
      return toast.error("Format prix incorrect");
    }
  };
  return (
    <StyledFormAddMenu>
      <h1>Création d'un menu*</h1>
      <span className="span-info-actif">
        *Par défaut, les menus ajoutés sont inactifs
      </span>
      <form onSubmit={(e) => sendForm(e)}>
        <div className="box-in-form">
          <input
            type="text"
            placeholder="Nom de votre menu*"
            onChange={(e) => setMenu(e.target.value)}
            value={menu ? menu : ""}
          />
        </div>
        <div className="box-in-form">
          <textarea
            placeholder="Description et contenu*"
            onChange={(e) => setDescription(e.target.value)}
            value={description ? description : ""}
          ></textarea>
        </div>
        <div className="box-in-form">
          <input
            className="input-price"
            type="text"
            placeholder="00.00"
            onChange={(e) => setPrice(e.target.value)}
            value={price ? price : ""}
          />
        </div>
        <div className="box-in-form">
          <input className="btn-form-menu" type="submit" value="Ajouter" />
        </div>
      </form>
    </StyledFormAddMenu>
  );
};

export default FormAddMenu;

const StyledFormAddMenu = styled.div`
  /* background: pink; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    text-align: center;
    font-size: 1.5em;
  }
  .span-info-actif {
    font-size: 0.9em;
    color: yellow;
  }
  form {
    display: flex;
    height: 35vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    border: solid 2px white;
    width: 50%;
  }
  form > .box-in-form {
    width: 100%;
  }
  form > .box-in-form > input {
    width: 100%;
    margin-bottom: 5px;
    outline: none;
    border: none;
    padding: 5px;
    border-radius: 5px;
    font-size: 1.1em;
  }
  form > .box-in-form > .input-price {
    width: 30%;
  }
  form > .box-in-form > textarea {
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    resize: none;
    outline: none;
    border: none;
    height: 10vh;
    font-size: 1.1em;
  }
  form > .box-in-form > .btn-form-menu {
    margin-top: 10px;
    width: 50%;
    padding: 3px;
    border-radius: 5px;
    border: none;
    outline: none;
    background: #bab5b5;
    box-shadow: 7px 7px 15px #979393, -7px -7px 15px #ddd7d7;
  }
  form > .box-in-form > .btn-form-menu:active {
    background: #bab5b5;
    box-shadow: inset 7px 7px 15px #979393, inset -7px -7px 15px #ddd7d7;
  }
`;
