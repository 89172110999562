import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { GoHomeFill } from "react-icons/go";
import { COLORS } from "../../../utils/Style/styleGlobale";
import { Dynamic } from "../../../Context/ContextDynamic/ContextDynamic";
import { toast } from "react-toastify";
import axios from "axios";
import { Data } from "../../../Context/ContextData/ContextData";

const Identification = () => {
  const [identification, setIdentification] = useState(false);
  const [codefast, setCodefast] = useState("");
  const [tableSelect, setTableSelect] = useState("");
  const [myName, setMyName] = useState("");
  const [idComp, setIdComp] = useState("");
  const [companyName, setCompanyName] = useState("");
  const { goPage, setLoading, setCookie } = Dynamic();
  const { setOrdersSended } = Data();

  //check if pseudo exist in model orderOrNote
  const checkIfPseudoExistInModelOrder = async () => {
    try {
      const res = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API}public/check/pseudo`,
        withCredentials: true,
        data: { nameClient: myName, idComp, table: tableSelect },
      });
      // console.log(res);
      if (res.data.error) {
        setLoading(false);
        return toast.error(res.data.error);
      }
      if (res.data.succes) {
        setLoading(false);
        const expiration24Hour = new Date();
        expiration24Hour.setHours(expiration24Hour.getHours() + 24);
        const valueCookie = {
          idComp,
          nameClient: myName,
          tableSelect,
          codefast,
          companyName,
        };
        setCookie("accesClient", valueCookie, {
          expires: expiration24Hour,
          path: "/",
        });
        goPage("/client");
        if (myName) {
          return toast.success("Bienvenue " + myName);
        }
        if (tableSelect) {
          return toast.success(
            "Bienvenue, vous êtes la table : " + tableSelect
          );
        }
      }
    } catch (error) {
      console.log(error);
      return toast.error(
        "Une erreur est survenue lors de la vérification de votre pseudo 🤧"
      );
    }
  };

  const chooseNameOrTable = () => {
    // const expirationOneHour = new Date();
    if (!tableSelect && !myName) {
      setLoading(false);
      return toast.error(
        "Vous devez choisir un identifiant ou un numéro de table"
      );
    } else if (tableSelect && myName) {
      setLoading(false);
      return toast.error("Vous devez choisir soit un pseudo, soit une table");
    } else {
      // expirationOneHour.setHours(expirationOneHour.getHours() + 1);
      if (tableSelect) {
        setLoading(false);
        if (isNaN(tableSelect)) return toast.error("Numéro non valide");
        if (tableSelect > 0) {
          checkIfPseudoExistInModelOrder();
        } else {
          //   setTableSelect(1);
          return toast.success("Erreur, Choisissez un numéro superieur à 0 🤧");
        }
      }

      //if pseudo exits
      if (myName) {
        setLoading(false);
        if (!isNaN(myName)) return toast.error("Pseudo avec numéro non valide");
        checkIfPseudoExistInModelOrder();
      }
    }
  };

  const checkIfCodeFastIsCorrect = async () => {
    if (!codefast) return toast.error("Un code est obligatoire");
    try {
      const res = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API}public/identification/code/fast`,
        withCredentials: true,
        data: { code: codefast },
      });

      setLoading(false);
      if (res.data.error) return toast.error(res.data.error);
      if (res.data.id) {
        setIdentification(true);
        setCompanyName(res.data.company);
        return setIdComp(res.data.id);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      return toast.error("Une erreur est survenue coté serveur");
    }
  };
  const valideFastCode = () => {
    // goPage("/client");
    setLoading(true);
    if (!identification) {
      checkIfCodeFastIsCorrect();
    } else {
      chooseNameOrTable();
    }
  };

  useEffect(() => {
    setOrdersSended([]);
  }, []);
  return (
    <StyledIdentification>
      <GoHomeFill className="icon-go-home" onClick={() => goPage("/")} />
      <h1>Identification</h1>
      <div className="old-form">
        {identification ? (
          <div className="box-choose-id">
            <span>Code Id : {codefast && codefast} </span>
            <input
              type="text"
              placeholder="Pseudo"
              onChange={(e) => setMyName(e.target.value)}
            />
            <span>---👇 ou selectionner une table 👇---</span>
            <input
              type="number"
              placeholder="1"
              className="choose-table"
              onChange={(e) => setTableSelect(e.target.value)}
            />
          </div>
        ) : (
          <input
            type="text"
            placeholder="Entrer le code de votre vendeur"
            onChange={(e) => setCodefast(e.target.value)}
          />
        )}
        <input type="button" value="Valider" onClick={() => valideFastCode()} />
      </div>
    </StyledIdentification>
  );
};

export default Identification;

const StyledIdentification = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  /* background: blue; */
  margin: 50px auto 0px;
  height: 50vh;
  background: ${COLORS.greenTransparent};
  border-radius: 10px;
  h1 {
    margin-bottom: 10px;
    letter-spacing: 5px;
  }
  .icon-go-home {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 2em;
    color: ${COLORS.green};
  }
  .old-form {
    display: flex;
    flex-direction: column;
    width: 70%;
    /* background: orange; */
    padding: 10px;
    .box-choose-id {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: ${COLORS.green};
      padding: 5px;
      border-radius: 5px;
      button {
        padding: 5px;
        outline: none;
        border: none;
        background: ${COLORS.blackgrey};
        font-weight: 800;
        color: white;
        border-radius: 5px;
      }
    }
    span {
      text-align: center;
      /* margin-top: 10px; */
      font-size: 0.7em;
    }
    .choose-table {
      width: 20%;
      margin: 0px 0px 15px 0px;
    }
    .choose-table::placeholder {
      padding: 1px;
      background: white;
      border-radius: 3px;
    }
    .choose-table:active {
      background: white;
    }
    input {
      padding: 3px;
      border-radius: 3px;
      outline: none;
      border: none;
      margin: 10px 0px 0px 0px;
      font-size: 1.1em;
    }
    input::placeholder {
      /* background: orange; */
    }
    input:last-child {
      background: ${COLORS.green};
      color: white;
    }
  }

  //width =< 320px
  @media screen and (max-width: 320px) {
    width: 90%;
    .old-form {
      width: 80%;
    }
  }
  @media screen and (max-width: 425px) {
    width: 90%;
    .old-form {
      width: 80%;
    }
  }
  @media screen and (min-width: 425px) {
    width: 90%;
    .old-form {
      width: 80%;
    }
  }
`;
