import React from "react";
import styled from "styled-components";
import { COLORS } from "../../utils/Style/styleGlobale";
import { Dynamic } from "../../Context/ContextDynamic/ContextDynamic";
import { MdDashboardCustomize } from "react-icons/md";

const Code = () => {
  const { goPage, codeTemporaire } = Dynamic();
  return (
    <StyledCode>
      <MdDashboardCustomize
        className="icon-go-home"
        onClick={() => goPage("/dashboard")}
      />
      <span>Code d'Identification: </span>
      <strong>{codeTemporaire !== "0" ? codeTemporaire : "Désactiver"}</strong>
    </StyledCode>
  );
};

export default Code;
const StyledCode = styled.div`
  background: ${COLORS.blackgrey};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .icon-go-home {
    position: absolute;
    bottom: 100px;
    left: 10px;
    font-size: 2em;
    color: ${COLORS.green};
  }
  span {
    color: white;
    font-size: 2em;
    margin-bottom: 10px;
  }
  strong {
    color: white;
    font-size: 6em;
  }
`;
