import React, { useEffect, useState } from "react";
import styled from "styled-components";
import FormAddMenu from "./Forms/FormAddMenu";
import FormAddProduct from "./Forms/FormAddProduct";
import FormAddCategorie from "./Forms/FormAddCategorie";
import axios from "axios";
import ProductsLists from "./ProductsLists/ProductsLists";
import MenusLists from "./MenusLists/MenusLists";
import DeleteAccount from "./DeleteAccount/DeleteAccount";
import FastCodeGenere from "./Forms/FastCodeGenere";
import { COLORS } from "../../../utils/Style/styleGlobale";
import { Profil } from "./Profil/Profil";
import { toast } from "react-toastify";

const Dashboard = () => {
  const [formCall, setFormCall] = useState("");
  const [listCall, setListCall] = useState("");
  const [displayTheForms, setDisplayTheForms] = useState(false);
  const [classMenu, setClassMenu] = useState(false);
  const [classProduct, setClassProduct] = useState(false);
  const [classCategorie, setClassCategorie] = useState(false);
  const [callGetCategoriesAgain, setCallGetCategoriesAgain] = useState(false);
  //data server
  const [categories, setCategories] = useState([]);
  const whatFormDisplay = () => {
    switch (formCall) {
      case "product":
        return (
          <FormAddProduct
            categories={categories}
            setCategories={setCategories}
            callGetCategoriesAgain={callGetCategoriesAgain}
          />
        );
      case "categorie":
        return (
          <FormAddCategorie
            setCategories={setCategories}
            categories={categories}
            callGetCategoriesAgain={callGetCategoriesAgain}
            setCallGetCategoriesAgain={setCallGetCategoriesAgain}
          />
        );
      // case "menu":
      //   return <FormAddMenu />;
      default:
        // return <FormAddMenu />;
        return (
          <FormAddProduct
            categories={categories}
            setCategories={setCategories}
            callGetCategoriesAgain={callGetCategoriesAgain}
          />
        );
    }
  };

  const formsDisplay = () => {
    return (
      <div className="choose-your-form">
        {" "}
        <div className="to-change-text-and-form">
          {/* <span
            className={classMenu ? "active-btn-bro" : undefined}
            onClick={() => setFormCall("menu")}
          >
            Ajouter un menu
          </span> */}
          <span
            className={classProduct ? "active-btn-bro" : undefined}
            onClick={() => setFormCall("product")}
          >
            Ajouter un produit
          </span>
          <span
            className={classCategorie ? "active-btn-bro" : undefined}
            onClick={() => setFormCall("categorie")}
          >
            Ajouter une catégorie
          </span>
        </div>
        {whatFormDisplay()}
      </div>
    );
  };

  //list menu and products
  const displayOneList = () => {
    switch (listCall) {
      case "products":
        return <ProductsLists categories={categories} />;
      // case "menus":
      //   return <MenusLists />;
      case "fastcode":
        return <FastCodeGenere />;
      case "profil":
        return <Profil />;
      default:
        return <ProductsLists categories={categories} />;
    }
  };

  const chooseList = (val) => {
    setListCall(val);
    setDisplayTheForms(true);
  };
  useEffect(() => {
    const toKnowBtnActive = () => {
      switch (formCall) {
        case "product":
          setClassProduct(true);
          setClassMenu(false);
          setClassCategorie(false);
          break;
        case "categorie":
          setClassProduct(false);
          setClassMenu(false);
          setClassCategorie(true);
          break;
        // case "menu":
        //   setClassProduct(false);
        //   setClassMenu(true);
        //   setClassCategorie(false);
        //   break;
        default:
          setClassProduct(true);
          // setClassMenu(false);
          setClassCategorie(false);
          break;
      }
    };

    toKnowBtnActive();
  }, [formCall]);

  useEffect(() => {
    const getAllCategorie = async () => {
      try {
        const res = await axios({
          method: "get",
          url: `${process.env.REACT_APP_API}categorie/all`,
          withCredentials: true,
        });
        // console.log(res);
        // console.log(res.data);
        setCategories(res.data);
      } catch (error) {
        console.log(error);
        return toast.error(
          "Une erreur s'est produite lors de la récupération des catégories"
        );
      }
    };
    getAllCategorie();
  }, [callGetCategoriesAgain]);

  return (
    <StyledDashboard>
      {!displayTheForms && formsDisplay()}
      <div className="list-produit-and-menu">
        {displayTheForms && (
          <span onClick={() => setDisplayTheForms(false)}>Retour</span>
        )}
        <span onClick={() => chooseList("products")}>Voir les produits</span>
        {/* <span onClick={() => chooseList("menus")}>Voir les menus</span> */}
        <span onClick={() => chooseList("fastcode")}>Code ID</span>
        <span onClick={() => chooseList("profil")} className="my-profil">
          Mon Profil
        </span>
      </div>
      {displayTheForms && displayOneList()}
      {/* <DeleteAccount /> */}
    </StyledDashboard>
  );
};

export default Dashboard;

const StyledDashboard = styled.div`
  /* background: pink; */
  /* margin-top: 10px; */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
  .choose-your-form {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .to-change-text-and-form {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .choose-your-form > .to-change-text-and-form > span {
    padding: 5px;
    margin: 0px 10px;
    border-radius: 5px;
    background: #bab5b5;
    box-shadow: 7px 7px 15px #979393, -7px -7px 15px #ddd7d7;
  }
  .choose-your-form > .to-change-text-and-form > span:active {
    background: #bab5b5;
    box-shadow: inset 7px 7px 15px #979393, inset -7px -7px 15px #ddd7d7;
  }
  .active-btn-bro {
    background: #bab5b5 !important;
    box-shadow: inset 7px 7px 15px #979393, inset -7px -7px 15px #ddd7d7 !important;
  }
  .list-produit-and-menu {
    position: absolute;
    left: 0px;
    top: 20px;
    /* background: greenyellow; */
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 30vh;
    .my-profil {
      cursor: pointer;
      background: ${COLORS.btnColor};
      color: white;
    }
  }
  .list-produit-and-menu > span {
    font-size: 1em;
    padding: 10px;
    border-radius: 10px;
    margin-top: 15px;
    cursor: pointer;
    background: #bab5b5;
    box-shadow: 7px 7px 15px #979393, -7px -7px 15px #ddd7d7;
  }
  .list-produit-and-menu > span:active {
    background: #bab5b5;
    box-shadow: inset 7px 7px 15px #979393, inset -7px -7px 15px #ddd7d7;
  }

  //width >= 425px
  @media screen and (max-width: 429px) {
    /* background: pink; */
    /* display: flex; */
    flex-direction: column;
    .list-produit-and-menu {
      position: relative;
      width: 100%;
      margin-top: 0px;
      /* background: blue; */
      height: 5vh;
      display: flex;
      top: 0px;
      flex-direction: row;
    }
    .list-produit-and-menu > span {
      padding: 5px;
      display: block;
      border-radius: 5px;
      margin-top: 10px;
    }
  }
  //width =< 425px
  @media screen and (min-width: 425px) {
  }
`;
