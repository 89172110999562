import React, { useState } from "react";
import { FaWindowClose } from "react-icons/fa";
import { toast } from "react-toastify";
import styled from "styled-components";
import "react-phone-number-input/style.css";
import { COLORS } from "../../../utils/Style/styleGlobale";
import PhoneInput from "react-phone-number-input";
// import PhoneInput from "react-phone-number-input/input";

const PopIfDelivery = ({
  textDelivery,
  setTextDelivery,
  setDisplayPopDelivery,
  telToDelivery,
  setTelToDelivery,
}) => {
  const addTextInOrder = () => {
    if (!textDelivery && !telToDelivery)
      return toast.error("Les champs sont vides");
    if (textDelivery.length > 70) return toast.error("Max 70 caractères");
    setDisplayPopDelivery(false);
  };
  return (
    <StyledPopIfDelivery onClick={() => setDisplayPopDelivery(false)}>
      <div
        className="sous-parent-delivery"
        onClick={(e) => e.stopPropagation()}
      >
        <h3>Information pour la livraison</h3>
        <FaWindowClose
          className="icon-close-pop-delivery"
          onClick={() => setDisplayPopDelivery(false)}
        />
        <div className="input-number">
          <label htmlFor="number-num">Numéro* :</label>
          <PhoneInput
            id="number-num"
            placeholder="Numéro de téléphone "
            value={telToDelivery}
            onChange={setTelToDelivery}
          />
          <p>*0694xxxxxx pour la guyane</p>
        </div>
        <div className="box-textarea">
          <label htmlFor="textarea">Adresse : </label>
          <textarea
            id="textarea"
            onChange={(e) => setTextDelivery(e.target.value)}
            placeholder="Information pour la livraison*"
          ></textarea>
          <p className={textDelivery.length > 70 ? "red" : ""}>
            Max 70 caractères{" "}
            {textDelivery && `|| ${textDelivery.length} caractères`}
          </p>
        </div>
        {textDelivery && telToDelivery && (
          <button onClick={addTextInOrder}>Ajouter</button>
        )}
      </div>
    </StyledPopIfDelivery>
  );
};

export default PopIfDelivery;
const StyledPopIfDelivery = styled.div`
  background: ${COLORS.greenTransparent};
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  position: absolute;
  z-index: 81;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  h3,
  label,
  p {
    color: white;
  }
  .sous-parent-delivery {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: solid 3px white;
    border-radius: 10px;
    background: ${COLORS.green};
    padding: 5px;
    width: 50%;
    .icon-close-pop-delivery {
      position: absolute;
      top: 5px;
      left: 5px;
      color: ${COLORS.red};
      font-size: 1.3em;
      cursor: pointer;
    }
    .red {
      color: ${COLORS.red};
    }
    .box-textarea,
    .input-number {
      display: flex;
      flex-direction: column;
      margin: 5px 0px;
      width: 50%;
      p {
        width: 70%;
        font-size: 0.7em;
        text-align: left;
      }
      textarea {
        height: 10vh;
        width: 100%;
        border-radius: 5px;
      }
      input::placeholder,
      textarea::placeholder {
        font-size: 0.8em;
      }
      label {
        margin-top: 3px;
        font-size: 1em;
        text-align: left;
        cursor: pointer;
      }
      input {
        width: 50%;
        padding: 3px;
        border-radius: 3px;
        border: none;
        outline: none;
      }
    }
    textarea,
    button {
      padding: 3px;
      border: none;
      outline: none;
      resize: none;
    }
    button {
      background: ${COLORS.btnColor};
      padding: 5px 50px;
      color: white;
      border-radius: 5px;
      cursor: pointer;
    }
  }

  //width >= 425px
  @media screen and (max-width: 425px) {
    .sous-parent-delivery {
      width: 90%;
      .box-textarea,
      .input-number {
        width: 80%;
      }
    }
    button {
      margin-top: 10px;
    }
  }
  //width =< 425px
  @media screen and (min-width: 425px) {
    .sous-parent-delivery {
      width: 90%;
      .box-textarea,
      .input-number {
        width: 80%;
      }
    }
    button {
      margin-top: 10px;
    }
  }
  //width =< 360px
  @media screen and (min-width: 360px) {
    .sous-parent-delivery {
      width: 90%;
      .box-textarea,
      .input-number {
        width: 80%;
      }
    }
    button {
      margin-top: 10px;
    }
  }
`;
