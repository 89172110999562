import React, { useState } from "react";
import styled from "styled-components";
import { COLORS } from "../../utils/Style/styleGlobale";
import { Dynamic } from "../../Context/ContextDynamic/ContextDynamic";
import { MdLogout } from "react-icons/md";
import Dashboard from "./Dashboard/Dashboard";

const Admin = () => {
  const { user, handleLogout } = Dynamic();

  return (
    <StyledAdmin>
      {user && (
        <>
          <strong className="strong-dashboard">DASHBOARD</strong>
          <div className="box-to-btn-logout">
            <MdLogout className="logout-btn" onClick={handleLogout} />
          </div>
          <Dashboard />
        </>
      )}
    </StyledAdmin>
  );
};

export default Admin;

const StyledAdmin = styled.div`
  background: ${COLORS.grey};
  height: 100vh;
  padding-top: 55px;
  padding-left: 10px;
  padding-right: 10px;
  .strong-dashboard {
    display: block;
    text-align: center;
    font-size: 1.5em;
    letter-spacing: 0.3em;
  }
  .box-to-btn-logout {
    /* background: blue; */
    display: flex;
    justify-content: space-between;
    /* padding: 5px; */
    .updat-password {
      color: ${COLORS.red};
    }
  }
  .logout-btn {
    font-size: 2.2em;
    color: white;
    border-radius: 50%;
    /* background: #bab5b5; */
    background: ${COLORS.btnColor};
    padding: 5px;
    /* box-shadow: 7px 7px 15px #979393, -7px -7px 15px #ddd7d7; */
    box-shadow: 2px 2px 5px ${COLORS.btnColor}, -2px -2px 5px ${COLORS.btnColor};
  }
  .logout-btn:active {
    box-shadow: inset 5px 5px 10px ${COLORS.btnColor},
      inset -5px -5px 1px ${COLORS.btnColor};
  }
`;
