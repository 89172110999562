import React from "react";
import styled from "styled-components";
import { GoHomeFill } from "react-icons/go";
import { Dynamic } from "../../Context/ContextDynamic/ContextDynamic";
import { MdDashboardCustomize } from "react-icons/md";
import { COLORS } from "../../utils/Style/styleGlobale";
import { useLocation } from "react-router-dom";

const HeaderApp = () => {
  const { user, goPage } = Dynamic();
  const urlLocation = useLocation();

  const displayIconAdapt = () => {
    return urlLocation.pathname === "/" || urlLocation.pathname === "/order" ? (
      <MdDashboardCustomize
        className="go-dashboard"
        onClick={() => goPage("/dashboard")}
      />
    ) : (
      <GoHomeFill className="go-dashboard" onClick={() => goPage("/")} />
    );
  };

  return (
    <StyledHeaderApp $css={user}>
      <img
        src="./LogoSeverSavvy.png"
        alt="logo nou link"
        onClick={() => goPage("/")}
      />
      <strong>Nou Link - Beta {user && `- ${user.pseudo}`}</strong>
      {user && displayIconAdapt()}
    </StyledHeaderApp>
  );
};

export default HeaderApp;

const StyledHeaderApp = styled.header`
  background: ${COLORS.green};
  display: flex;
  align-items: center;
  justify-content: ${({ $css }) => ($css ? "space-between" : "center")};
  padding: 5px;
  position: fixed;
  z-index: 50;
  top: 0px;
  width: 100%;
  img {
    width: 3%;
    cursor: pointer;
  }
  strong {
    text-align: center;
    color: white;
  }
  .go-dashboard {
    font-size: 2.2em;
    margin-right: 10px;
    padding: 5px;
    border-radius: 50px;
    /* background: ${COLORS.green}; */
    background: ${COLORS.btnColor};
    /* box-shadow: 7px 7px 15px #02b065, -7px -7px 15px #04ff95; */
    box-shadow: 3px 3px 10px ${COLORS.btnColor},
      -3px -3px 10px ${COLORS.btnColor};
    cursor: pointer;
  }
  .go-dashboard:active {
    /* box-shadow: inset 7px 7px 15px #02b065, inset -7px -7px 15px #04ff95; */
    box-shadow: inset 7px 7px 15px ${COLORS.btnColor},
      inset -7px -7px 15px ${COLORS.btnColor};
  }
  //width =< 425px
  @media screen and (max-width: 429px) {
    img {
      width: 10% !important;
    }
  }
`;
