import React from "react";
import { CgProfile } from "react-icons/cg";
import { FaFacebook } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import styled from "styled-components";
import { COLORS } from "../../../utils/Style/styleGlobale";
import { MdDeliveryDining } from "react-icons/md";
import { FaWindowClose } from "react-icons/fa";
import { BsCopy } from "react-icons/bs";
import { toast } from "react-toastify";
import { MdDescription } from "react-icons/md";
import { FaRegCreditCard } from "react-icons/fa";
import { CiMoneyCheck1 } from "react-icons/ci";

const PopInfoSeller = ({ setPopInfoUser, popInfoUser, toPopInfoUser }) => {
  // console.log(toPopInfoUser);
  const copyReseau = (link) => {
    navigator.clipboard
      .writeText(link)
      .then(() => {
        return toast.success(`${link} est copié`);
      })
      .catch((error) => {
        console.log(error);
        return toast.error("Une erreur est survenue lors de la copie");
      });
  };
  return (
    <StyledPopInfoSeller onClick={() => setPopInfoUser(!popInfoUser)}>
      <div
        className="sous-parent-info-seller"
        onClick={(e) => e.stopPropagation()}
      >
        <FaWindowClose
          className="icon-close-pop"
          onClick={() => setPopInfoUser(!popInfoUser)}
        />
        <strong className="strong-profil">
          <CgProfile className="ico-profil-seller-to-client" />
          {toPopInfoUser.pseudo}
        </strong>
        {toPopInfoUser.description && (
          <div>
            <strong>
              {" "}
              <MdDescription className="icon-descript" /> Infos :
            </strong>
            <p>{toPopInfoUser.description}</p>
          </div>
        )}
        {toPopInfoUser.facebook && (
          <strong onClick={() => copyReseau(toPopInfoUser.facebook)}>
            <FaFacebook className="icon-to-client facebook" />{" "}
            {toPopInfoUser.facebook}
            <BsCopy className="ico-copy" />
          </strong>
        )}
        {toPopInfoUser.instagram && (
          <strong onClick={() => copyReseau(toPopInfoUser.instagram)}>
            {" "}
            <RiInstagramFill className="icon-to-client instagram" />{" "}
            {toPopInfoUser.instagram}
            <BsCopy className="ico-copy" />
          </strong>
        )}
        {toPopInfoUser.delivery && toPopInfoUser.delivery === 1 ? (
          <strong>
            {" "}
            <MdDeliveryDining className="delivery" /> Livraison possible{" "}
          </strong>
        ) : (
          <strong>
            <MdDeliveryDining className="delivery-not" /> Pas de livraison ❌
          </strong>
        )}
        {toPopInfoUser.creditcard && toPopInfoUser.creditcard === 1 ? (
          <strong>
            {" "}
            <FaRegCreditCard className="creditcard" /> CB acceptée{" "}
          </strong>
        ) : (
          <strong>
            {" "}
            <FaRegCreditCard className="creditcard-not" /> CB non acceptée ❌
          </strong>
        )}
        {toPopInfoUser.cheque && toPopInfoUser.cheque === 1 ? (
          <strong>
            {" "}
            <CiMoneyCheck1 className="cheque" /> Chèque accepté
          </strong>
        ) : (
          <strong>
            {" "}
            <CiMoneyCheck1 className="cheque-not" /> Chèque non accepté ❌
          </strong>
        )}
      </div>
    </StyledPopInfoSeller>
  );
};

export default PopInfoSeller;

const StyledPopInfoSeller = styled.div`
  position: fixed;
  z-index: 60;
  width: 100%;
  height: 100%;
  background: ${COLORS.greenTransparent};
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  padding-top: 20px;
  strong,
  p {
    color: white;
  }
  .sous-parent-info-seller {
    background: ${COLORS.green};
    position: relative;
    width: 50%;
    margin: 10px auto;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    border: solid 3px white;
    > div > strong > .icon-descript {
      font-size: 1.5em;
      margin-right: 5px;
      color: white;
    }
    .icon-close-pop {
      position: absolute;
      top: 0px;
      right: 5px;
      color: ${COLORS.red};
      font-size: 1.5em;
      cursor: pointer;
    }
    .strong-profil {
      display: flex;
      flex-direction: column;
      justify-content: center;
      cursor: default;
      width: 100%;
    }
    strong {
      /* background: blue; */
      margin-top: 15px;
      display: flex;
      align-items: center;
      cursor: pointer;
      width: 40%;
      .ico-profil-seller-to-client {
        font-size: 3.5em;
        margin-right: 5px;
        color: white;
      }
      .ico-copy {
        margin-left: 5px;
        color: white;
      }
      .facebook {
        color: ${COLORS.facebook};
      }
      .instagram {
        color: ${COLORS.instagram};
      }
      .delivery-not,
      .creditcard-not,
      .cheque-not {
        color: ${COLORS.red};
        font-size: 1.5em;
        margin-right: 5px;
      }
      .delivery,
      .creditcard,
      .cheque {
        color: ${COLORS.validOnBlue};
        font-size: 1.5em;
        margin-right: 5px;
      }
      .icon-to-client {
        font-size: 1.5em;
        margin-right: 5px;
      }
    }
  }
  //width >= 425px
  @media screen and (max-width: 429px) {
    .sous-parent-info-seller {
      width: 90%;
      strong {
        width: 75%;
      }
    }
  }
`;
