import React, { useState } from "react";
import { GiCampCookingPot } from "react-icons/gi";
import styled from "styled-components";
import { Dynamic } from "../../Context/ContextDynamic/ContextDynamic";
import ButtonNav from "../../utils/ButtonNav/ButtonNav";
import Modal from "../../Components/HomeToOrder/Modal";
import { COLORS } from "../../utils/Style/styleGlobale";
import { GoHomeFill } from "react-icons/go";

const Order = () => {
  const { goPage } = Dynamic();
  return (
    <StyledOrder>
      <div className="box-to-button">
        {/* <ButtonNav
          text={"Commandes en cours"}
          actionClick={() => goPage("/")}
        /> */}
        {/* <GiCampCookingPot onClick={() => goPage("/")} className="icon-table" /> */}
        <GoHomeFill onClick={() => goPage("/")} className="icon-table" />
      </div>
      <strong>Nouvelle commande</strong>
      <div className="display-modal-table-current">
        <Modal />
      </div>
    </StyledOrder>
  );
};

export default Order;

const StyledOrder = styled.div`
  background: #8a8a8a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding-top: 20px;
  height: 100%;
  .box-to-button {
    /* background: green; */
    width: 50%;
    display: flex;
    justify-content: center;
    padding: 10px;
    .icon-table {
      font-size: 3.5em;
      color: ${COLORS.green};
      padding: 5px;
      border-radius: 5px;
      box-shadow: 7px 7px 15px #707070, -7px -7px 15px #a4a4a4;
    }
    .icon-table:active {
      box-shadow: inset 7px 7px 15px #707070, inset -7px -7px 15px #a4a4a4;
    }
  }
  .box-to-button > button {
    background: #8a8a8a;
    box-shadow: 10px 10px 20px #777777, -10px -10px 20px #9d9d9d;
    color: orange;
  }
  .box-to-button > button:active {
    box-shadow: inset 10px 10px 20px #777777, inset -10px -10px 20px #9d9d9d;
  }
  .display-modal-table-current {
    width: 700px;
    /* height: 70%; */
    display: flex;
    /* background: blue; */
    /* justify-content: space-between; */
    justify-content: space-evenly;
    align-items: center;
    overflow-y: auto;
  }
  strong {
    margin: 5px 0px;
    font-size: 2em;
  }
  //tablette 768px
  @media screen and (max-width: 768px) {
    height: 100%;
  }
`;
