import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { SocketContext } from "../ContextSocket/ContextSocket";
import { Dynamic } from "../ContextDynamic/ContextDynamic";

const ContextData = createContext();

export const ContextDataProvider = ({ children }) => {
  const [dataMongo, setDataMongo] = useState([]);
  const [menusMongo, setMenusMongo] = useState([]);
  const [dataToPopUp, setDataToPopUp] = useState("");
  const [ordersData, setOrdersData] = useState([]);
  const [textCommande, setTextCommande] = useState("");
  const [oldOrdersData, setOldOrdersData] = useState([]);
  // const [ordersNewStatus, setOrdersNewStatus] = useState([]);
  const [ordersSended, setOrdersSended] = useState([]);
  const { user, setUser, setCodeTemporaire, token, cookies } = Dynamic();
  const { socket } = SocketContext();

  //data in dataBase
  const getAllOrders = async () => {
    // console.log("j'ai joué haha");
    // console.log(token);
    if (user) {
      try {
        const res = await axios({
          method: "get",
          url: `${process.env.REACT_APP_API}order/all`,
          withCredentials: true,
        });
        // console.log(res.data);
        if (res.data.succes) {
          setTextCommande(res.data.succes);
        } else {
          // setOrdersData(res.data);
          setOrdersData(res.data.notvalid);
          setOldOrdersData(res.data.valid);
        }
        // if (res.data.error) return toast.error(res.data.error);
      } catch (error) {
        console.log(error);
        toast.error(
          "Une erreur est survenue lors de la récupération des commandes"
        );
      }
    }
  };
  useEffect(() => {
    //call all data in mogo
    // console.log(token);
    getAllOrders();
    if (!user) {
      setDataMongo([]);
      setMenusMongo([]);
      setOrdersData([]);
      setOldOrdersData([]);
      setOrdersSended([]);
    }
  }, [user]);
  // Création d'un contexte audio
  const audioContext = new (window.AudioContext || window.webkitAudioContext)();

  // Fonction pour charger et jouer le fichier audio
  const alertNewOrder = async () => {
    try {
      // Charger le fichier audio
      const response = await fetch("/items/audio/neworder.wav");
      const arrayBuffer = await response.arrayBuffer();

      // Décoder le fichier audio
      const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

      // Créer une source audio
      const source = audioContext.createBufferSource();
      source.buffer = audioBuffer;

      // Connecter la source au contexte audio et jouer
      source.connect(audioContext.destination);
      source.start(0);
    } catch (error) {
      console.error("Erreur lors de la lecture de l'audio", error);
    }
  };
  //to socket
  const toRealTimeDelete = () => {
    if (socket) {
      const handleAddOrdersSocket = (data) => {
        if (data) {
          setOrdersData(data);
          if (user) {
            // console.log(user);
            if (user.notificationson === 1) {
              console.log(user.notificationson);
              alertNewOrder();
            }
          }
          // }
        }
      };

      const handleDeleteSocket = (data) => {
        if (data) {
          // console.log(data);
          setOrdersData(data.notvalid);
          setOldOrdersData(data.valid);
        }
      };
      const handleValidSocket = (data) => {
        if (data) {
          setOrdersData(data.notvalid);
          setOldOrdersData(data.valid);
        }
      };

      const handleChangeStyleSocket = (data) => {
        if (data) {
          console.log(data);
        }
      };
      const handleUpdateUserSocket = (data) => {
        if (data) {
          // console.log(data);
          setUser(data);
          setCodeTemporaire(data.codefast);
        }
      };

      //check status if valid to client Fast
      const spyStatusValidOrNoteOrders = (data) => {
        if (data) {
          // console.log(data);
          setOrdersSended(data);
        }
      };

      //ecoute la room
      if (user) {
        socket.emit("join-room", user._id);
      }

      //pour commande internet
      if (cookies.accesClient) {
        if (cookies.accesClient.idComp) {
          socket.emit("join-room", cookies.accesClient.idComp);
        }
      }

      //we listen socket - evenement
      socket.on("newOrdersAfterNewAdd", handleAddOrdersSocket);
      socket.on("newOrderAfterDelete", handleDeleteSocket);
      socket.on("newOrderAfterValid", handleValidSocket);
      socket.on("toChangeStyle", handleChangeStyleSocket);
      socket.on("codeFastReponseSend", handleUpdateUserSocket);
      socket.on("checkNewStatusOrderFastClient", spyStatusValidOrNoteOrders);

      //on discribe evenement
      return () => {
        socket.off("newOrdersAfterNewAdd", handleAddOrdersSocket);
        socket.off("newOrderAfterDelete", handleDeleteSocket);
        socket.off("newOrderAfterValid", handleValidSocket);
        socket.off("toChangeStyle", handleChangeStyleSocket);
        socket.off("codeFastReponseSend", handleUpdateUserSocket);
        socket.off("checkNewStatusOrderFastClient", spyStatusValidOrNoteOrders);
        // socket.disconnect();
      };
    }
  };
  useEffect(() => {
    toRealTimeDelete();
  }, [socket, user]);

  //fecth data mongoDB - products
  const getAllData = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API}product/all`,
        withCredentials: true,
      });
      if (res.data.length > 0) {
        // console.log(res);
        setDataMongo(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //fecth data mongoDB - Menus
  const getAllMenusExist = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API}menu/all`,
        withCredentials: true,
      });
      // console.log(res);
      if (res.data.length > 0) {
        setMenusMongo(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllData();
    getAllMenusExist();
  }, []);
  useEffect(() => {
    if (!user && token) {
      setDataMongo([]);
      setMenusMongo([]);
      setOrdersData([]);
      setOldOrdersData([]);
    }
  }, [user, token]);
  return (
    <ContextData.Provider
      value={{
        dataMongo,
        setDataMongo,
        menusMongo,
        setMenusMongo,
        dataToPopUp,
        setDataToPopUp,
        ordersData,
        textCommande,
        setTextCommande,
        getAllOrders,
        oldOrdersData,
        setOldOrdersData,
        getAllData,
        getAllMenusExist,
        // setOrdersNewStatus,
        // ordersNewStatus,
        ordersSended,
        setOrdersSended,
        alertNewOrder,
      }}
    >
      {children}
    </ContextData.Provider>
  );
};

export const Data = () => useContext(ContextData);
