import React, { useState } from "react";
import styled from "styled-components";
import { Dynamic } from "../../../../Context/ContextDynamic/ContextDynamic";
import { toast } from "react-toastify";
import axios from "axios";
import { FaArrowCircleRight } from "react-icons/fa";
import { COLORS } from "../../../../utils/Style/styleGlobale";

const Register = () => {
  const [pseudo, setPseudo] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConf, setPasswordConf] = useState("");
  const [code, setCode] = useState("");
  const [acceptCgu, setAcceptCgu] = useState(false);
  // const [showPassword, setShowPassword] = useState(false);
  // const [showPasswordConf, setShowPasswordConf] = useState(false);
  // const [showCode, setShowCode] = useState(false);
  const { formConnexionRegister, setFormConnexionRegister } = Dynamic();
  const handleRegister = async (e) => {
    e.preventDefault();
    if (!acceptCgu)
      return toast.error(
        "Les Cgu et Politique de confidentialité doivent être acceptées pour continuer"
      );
    if (!pseudo || !email || !password || !code)
      return toast.error("Tous champs sont nécéssaires");
    if (password !== passwordConf)
      return toast.error("Mot de passe ne correspondent pas ");
    try {
      const res = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API}auth/register`,
        withCredentials: true,
        data: {
          pseudo,
          email,
          password,
          code,
        },
      });
      // console.log(res);
      if (res.data.error) return toast.error(res.data.error);
      if (res.data.succes) {
        setPseudo("");
        setEmail("");
        setPassword("");
        setPasswordConf("");
        setCode("");
        return toast.success(res.data.succes);
      }
    } catch (error) {
      console.log(error);
      toast.error("Erreur lors de l'inscription");
    }
  };
  return (
    <StyledRegister onSubmit={(e) => handleRegister(e)} $css={acceptCgu}>
      <strong onClick={() => setFormConnexionRegister(!formConnexionRegister)}>
        {formConnexionRegister && "Connexion"}
        <FaArrowCircleRight className="ico-arrow" />
      </strong>
      <input
        type="text"
        placeholder="Nom business"
        onChange={(e) => setPseudo(e.target.value)}
        value={pseudo ? pseudo : ""}
      />
      <input
        type="email"
        placeholder="Email"
        onChange={(e) => setEmail(e.target.value)}
        value={email ? email : ""}
      />
      <input
        type="password"
        placeholder="Mot de passe"
        onChange={(e) => setPassword(e.target.value)}
        value={password ? password : ""}
      />
      <input
        type="password"
        placeholder="Confirmation du mot de passe"
        onChange={(e) => setPasswordConf(e.target.value)}
        value={passwordConf ? passwordConf : ""}
      />
      <input
        type="password"
        placeholder="Code d'inscription"
        onChange={(e) => setCode(e.target.value)}
        value={code ? code : ""}
      />
      <div className="box-accept-cgu">
        <input
          type="checkbox"
          className="input-checkbox"
          onChange={() => setAcceptCgu(!acceptCgu)}
        />
        <p>
          J'ai lu et acceptez les CGU et notre politique de confidentialité.
          "Voir plus" pour afficher les cgu
        </p>
      </div>
      {acceptCgu && <input type="submit" value="Inscription" />}
    </StyledRegister>
  );
};

export default Register;
const StyledRegister = styled.form`
  /* background: #749f68; */
  background: ${COLORS.green};
  display: flex;
  padding: 5px;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  strong {
    font-size: 2em;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: ${COLORS.btnColor};
    .ico-arrow {
      margin-left: 5px;
    }
  }
  input {
    width: 70%;
    padding: 5px;
    outline: none;
    border: none;
    margin-top: 10px;
    font-size: 1em;
    border-radius: 5px;
  }
  input:last-child {
    width: 50%;
    cursor: pointer;
  }
  .box-accept-cgu {
    padding: 0px;
    width: 100%;
    /* background: blue; */
    margin-top: 5px;
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    .input-checkbox {
      width: 3%;
      margin: 0px;
    }
    p {
      margin: 0px;
      padding: 0px;
      font-size: 0.5em;
      color: ${({ $css }) => ($css ? COLORS.blackgrey : "orange")};
      text-align: center;
    }
  }

  //tablette 768px
  @media screen and (max-width: 768px) {
    width: 60%;
  }

  //width =< 320px
  @media screen and (max-width: 320px) {
    width: 90%;
    strong {
      font-size: 1.5em;
    }
    input {
      width: 90%;
      font-size: 1em;
    }
  }

  //width >= 425px
  @media screen and (max-width: 425px) {
    width: 90%;
    strong {
      font-size: 1.5em;
    }
    input {
      width: 90%;
      font-size: 1em;
    }
  }

  //width =< 425px
  @media screen and (min-width: 425px) {
    width: 90%;
    strong {
      font-size: 1.5em;
    }
    input {
      width: 90%;
      font-size: 1em;
    }
  }
`;
