import { createContext, useContext, useState } from "react";

const ContextOrderCurrent = createContext();

export const ContextOrderCurrentProvider = ({ children }) => {
  const [orderAllUser, setOrderAllUser] = useState([]);
  const [orderCurrentMenu, setOrderCurrentMenu] = useState([]);
  const [suplementCurrent, setSuplementCurrent] = useState(0);
  const [fritesCurrent, setFritesCurrent] = useState(0);
  const [detailsSuplement, setDetailsSuplement] = useState([]);
  const [detailsFrites, setDetailsFrites] = useState([]);
  const [tableCurrent, setTableCurrent] = useState(0);
  const [dessertsCurrent, setDessertsCurrent] = useState([]);
  const [platsCurrent, setPlatsCurrent] = useState([]);
  const [boissonsCurrent, setBoissonsCurrent] = useState([]);
  return (
    <ContextOrderCurrent.Provider
      value={{
        orderAllUser,
        setOrderAllUser,
        orderCurrentMenu,
        setOrderCurrentMenu,
        suplementCurrent,
        setSuplementCurrent,
        fritesCurrent,
        setFritesCurrent,
        detailsSuplement,
        setDetailsSuplement,
        detailsFrites,
        setDetailsFrites,
        tableCurrent,
        setTableCurrent,
        dessertsCurrent,
        setDessertsCurrent,
        platsCurrent,
        setPlatsCurrent,
        boissonsCurrent,
        setBoissonsCurrent,
      }}
    >
      {children}
    </ContextOrderCurrent.Provider>
  );
};

export const OrderCurrent = () => useContext(ContextOrderCurrent);
