import React, { useState } from "react";
import styled from "styled-components";
import { COLORS } from "../../utils/Style/styleGlobale";
import { MdClose } from "react-icons/md";
import PolitiqueConfidentiality from "./PolitiqueConfidentiality";
import Cgu from "./Cgu";
import MentionsLegales from "./MentionsLegales";
import Conseil from "./Conseil";

const FooterApp = () => {
  const [displayMore, setDisplayMore] = useState(false);
  const [title, setTitle] = useState("");
  const choiceWhenClick = (choice) => {
    switch (choice) {
      case "poli":
        setTitle("Politique de confidentialité");
        break;
      case "cgu":
        setTitle("Conditions générale d'utilisation");
        break;
      case "legal":
        setTitle("Mentions Légales");
        break;
      case "conseil":
        setTitle("Conseil pour une meilleure utilisation de NouLink");
        break;

      default:
        break;
    }
  };

  const displayTheChoice = () => {
    if (title && displayMore) {
      switch (title) {
        case "Politique de confidentialité":
          return (
            <div className="pop-in-footer">
              <PolitiqueConfidentiality title={title} />
            </div>
          );
        case "Conditions générale d'utilisation":
          return (
            <div className="pop-in-footer">
              <Cgu title={title} />
            </div>
          );
        case "Mentions Légales":
          return (
            <div className="pop-in-footer">
              <MentionsLegales title={title} />
            </div>
          );
        case "Conseil pour une meilleure utilisation de NouLink":
          return (
            <div className="pop-in-footer">
              <Conseil title={title} />
            </div>
          );

        default:
          break;
      }
    }
  };

  const closeAll = () => {
    setDisplayMore(false);
    setTitle("");
  };
  return (
    <StyledFooterApp $css={displayMore}>
      {displayTheChoice()}
      <span className="first-span">Nou Link - 2024</span>
      {!displayMore && (
        <span className="see-plus" onClick={() => setDisplayMore(true)}>
          Voir plus(Important !){" "}
        </span>
      )}
      {displayMore && (
        <div className="box-more-info-footer">
          <span
            onClick={() => choiceWhenClick("conseil")}
            className="text-conseil"
          >
            Conseils d'utilisation
          </span>
          <span onClick={() => choiceWhenClick("poli")}>
            Politique de confidentialité
          </span>
          <span onClick={() => choiceWhenClick("cgu")}>CGU</span>
          <span onClick={() => choiceWhenClick("legal")}>Mention Légale</span>
        </div>
      )}
      {displayMore && (
        <MdClose className="close-more-footer" onClick={() => closeAll()} />
      )}
    </StyledFooterApp>
  );
};

export default FooterApp;

const StyledFooterApp = styled.footer`
  background: grey;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 50;
  width: 100%;
  height: ${({ $css }) => ($css ? "20vh" : "5vh")};
  bottom: 0px;
  .first-span {
    color: ${COLORS.green};
    font-size: 0.8em !important;
    font-weight: bold;
  }
  span {
    color: ${COLORS.green};
    font-size: 0.6em !important;
    cursor: pointer;
  }
  .see-plus {
    font-size: 0.8em;
  }
  .box-more-info-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
      margin-top: 5px;
      cursor: pointer;
    }
  }
  .text-conseil {
    color: white;
  }
  .close-more-footer {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    color: ${COLORS.red};
    font-size: 2em;
    font-weight: 800;
  }

  //pop
  .pop-in-footer {
    position: fixed;
    z-index: 20;
    height: 60vh;
    width: 80%;
    background: white;
    border: solid 3px ${COLORS.grey};
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    padding: 10px;
    border-radius: 10px;
    overflow-y: scroll;
  }

  //width >= 425px
  @media screen and (max-width: 425px) {
    height: ${({ $css }) => ($css ? "30vh" : "6vh")};
    .pop-in-footer {
      top: 40%;
      width: 90%;
      height: 50vh;
    }
  }
  //width =< 425px
  @media screen and (min-width: 425px) {
    height: ${({ $css }) => ($css ? "30vh" : "6vh")};
    .pop-in-footer {
      top: 40%;
      width: 90%;
      height: 50vh;
    }
  }
  //width =< 360px
  @media screen and (min-width: 360px) {
    height: ${({ $css }) => ($css ? "30vh" : "6vh")};
    .pop-in-footer {
      top: 40%;
      width: 90%;
      height: 50vh;
    }
  }
`;
