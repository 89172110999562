import React, { useEffect } from "react";
import styled from "styled-components";

const OrderSendedToSeeInPop = ({ order }) => {
  return (
    <StyledOrderSendedToSeeInPop>
      {order.map((pair, index) => {
        // Obtenir la clé (nom de l'index)
        const indexName = Object.keys(pair)[0];

        return (
          <div key={index} className="box-detail-order-sended">
            <h2>{indexName}</h2>
            {/* Parcourir chaque élément dans le tableau correspondant à cette clé */}
            {pair[indexName].map((item, itemIndex) => (
              <div key={itemIndex} className="product-n-price">
                <p>{item.name}</p>
                <p>{item.price.toFixed(2)} €</p>
              </div>
            ))}
          </div>
        );
      })}
    </StyledOrderSendedToSeeInPop>
  );
};

export default OrderSendedToSeeInPop;

const StyledOrderSendedToSeeInPop = styled.div`
  /* background: pink; */
  .box-detail-order-sended {
    h2 {
      color: white;
      margin: 10px 0px 0px 0px;
    }
    .product-n-price {
      border-bottom: dashed 1px white;
      display: flex;
      justify-content: space-between;
      p {
        color: white;
      }
    }
  }
`;
