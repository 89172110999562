import React, { useState } from "react";
import styled from "styled-components";
import { Dynamic } from "../../Context/ContextDynamic/ContextDynamic";
import ModaleConnexionOrRegister from "../../Components/Home/Forms/Modal/ModaleConnexionOrRegister";
import OrdersCurrent from "./OrdersCurrent/OrdersCurrent";
import Order from "../Order/Order";
import Status from "../../Components/Status/Status";

const Home = () => {
  const { user, admin } = Dynamic();

  return (
    <StyledHome>
      {admin && <Status />}
      {user ? <OrdersCurrent /> : <ModaleConnexionOrRegister />}
    </StyledHome>
  );
};

export default Home;

const StyledHome = styled.div`
  /* background: blue; */
  height: 100%;
  width: 100%;
`;
