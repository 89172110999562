import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FaCheck } from "react-icons/fa";
import { COLORS } from "../../../../utils/Style/styleGlobale";
import { toast } from "react-toastify";
import axios from "axios";
import { Dynamic } from "../../../../Context/ContextDynamic/ContextDynamic";
const FormDescription = ({ setDescriptionUpdate }) => {
  const [description, setDescription] = useState("");
  const { user, setUser } = Dynamic();
  const subDescription = async (e) => {
    e.preventDefault();
    if (description.length > 100) return toast.error("Max 100 caractères");
    if (!description) {
      setDescription("");
    }
    try {
      const res = await axios({
        method: "put",
        url: `${process.env.REACT_APP_API}user/update/description/${user._id}`,
        withCredentials: true,
        data: { description },
      });
      if (res.data.user) {
        setUser(res.data.user);
        return setDescriptionUpdate(false);
      }
    } catch (error) {
      console.log(error);
      return toast.error("Une erreur est survenue");
    }
  };

  useEffect(() => {
    if (user.description) {
      setDescription(user.description);
    }
  }, []);
  return (
    <StyledFormDescription onSubmit={(e) => subDescription(e)}>
      <h3>Description</h3>
      {description.length > 1 && (
        <span className={description.length > 100 ? "red" : ""}>
          {" "}
          {description.length} caractères
        </span>
      )}
      <textarea
        placeholder="Description,un message, horaire, lieux..."
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      ></textarea>
      <FaCheck
        className="icon-valid-description"
        onClick={(e) => subDescription(e)}
      />
    </StyledFormDescription>
  );
};

export default FormDescription;

const StyledFormDescription = styled.form`
  /* background: pink; */
  display: flex;
  flex-direction: column;
  position: relative;
  width: 70%;
  margin: 0px auto;
  /* padding: 5px; */
  h3 {
    text-align: center;
    margin-bottom: 3px;
  }
  span {
    text-align: center;
    font-size: 0.8em;
    color: yellow;
  }
  .red {
    color: ${COLORS.red};
  }
  textarea {
    width: 100%;
    margin: 5px auto;
    resize: none;
    padding: 3px;
    border-radius: 5px;
    outline: none;
    border: none;
    height: 10vh;
  }
  textarea::-webkit-scrollbar {
    display: none; /* Masquer la barre de défilement */
  }
  input {
    width: 30%;
    margin: 0px auto;
    padding: 3px;
    border: none;
    outline: none;
    border-radius: 5px;
  }
  .icon-valid-description {
    position: absolute;
    top: 0px;
    right: 0px;
    /* color: ${COLORS.green}; */
    color: white;
    padding: 5px;
    font-size: 1.5em;
    background: ${COLORS.blackgrey};
    border-radius: 5px;
    cursor: pointer;
  }
`;
