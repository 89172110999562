import React from "react";
import { Navigate } from "react-router-dom";
import { Dynamic } from "../../Context/ContextDynamic/ContextDynamic";

const PrivateRouteHome = ({ children }) => {
  const { user } = Dynamic();
  if (!user) {
    return <Navigate to="/" />;
  }
  return children;
};

export default PrivateRouteHome;
