import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ContextDynamicProvider } from "./Context/ContextDynamic/ContextDynamic";
import { ContextStaticProvider } from "./Context/ContextStatic/ContextStatic";
import { ContextDataProvider } from "./Context/ContextData/ContextData";
import { ContextOrderCurrentProvider } from "./Context/ContextOrderCurrent/ContextOrderCurrent";
import { ContextSocketProvider } from "./Context/ContextSocket/ContextSocket";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ContextDynamicProvider>
        <ContextSocketProvider>
          <ContextDataProvider>
            <ContextStaticProvider>
              <ContextOrderCurrentProvider>
                <App />
              </ContextOrderCurrentProvider>
            </ContextStaticProvider>
          </ContextDataProvider>
        </ContextSocketProvider>
      </ContextDynamicProvider>
    </BrowserRouter>
  </React.StrictMode>
);
//contexStatic not use
