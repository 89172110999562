import React from "react";
import styled from "styled-components";
import { COLORS } from "../Style/styleGlobale";

const ButtonsChoose = ({ text, actionClick, icon }) => {
  return (
    <StyledButtonsChoose onClick={actionClick}>
      <span>{text}</span>
      {icon}
    </StyledButtonsChoose>
  );
};

export default ButtonsChoose;

const StyledButtonsChoose = styled.div`
  /* background: pink; */
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column-reverse;
  align-items: center;
  position: relative;
  .all-icon {
    font-size: 4em;
    /* color: white; */
    color: ${COLORS.green};
  }
  span {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, 60%);
    font-size: 2em;
    opacity: 0.3;
    color: orange;
    /* color: ${COLORS.green}; */
  }
  //tablette 768px
  @media screen and (max-width: 768px) {
    .all-icon {
      font-size: 6em;
    }
  }
`;
