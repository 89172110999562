import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";

const ContextDynamic = createContext();

export const ContextDynamicProvider = ({ children }) => {
  const [formConnexionRegister, setFormConnexionRegister] = useState(false);
  const [user, setUser] = useState(null);
  const [token, setToken] = useState("");
  const [admin, setAdmin] = useState(false);
  const [navCompo, setNavCompo] = useState(false);
  const [pop, setPop] = useState(false);
  const [toPopMenus, setToPopMenus] = useState(false);
  const [popTableChoose, setPopTableChoose] = useState(false);
  const [toPop, setToPop] = useState([]);
  const [orderCurrent, setOrderCurrent] = useState([]);
  const [orderAdding, setOrderAdding] = useState([]);
  const goPage = useNavigate();
  const [loading, setLoading] = useState(true);
  const [codeTemporaire, setCodeTemporaire] = useState("0");
  const [cookies, setCookie, removeCookie] = useCookies(["accesClient"]);

  const checkSessionIfExist = async () => {
    setLoading(true);
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API}so`,
        withCredentials: true,
      });
      // console.log(res);
      if (res.data.token) {
        setLoading(false);
        setToken(res.data.token);
      }
      if (res.data.redirection) {
        setTimeout(() => {
          window.location.href = "https://www.google.com";
          return toast.error(res.data.block);
        }, 3000);
        return toast.error("Vous êtes suspendu");
      }
      if (res.data.user) {
        setLoading(false);
        setUser(res.data.user);
      }
      if (res.data.connexion) {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleLogout = async () => {
    if (window.confirm("Ok pour vous déconnecter...")) {
      try {
        const res = await axios({
          method: "get",
          url: `${process.env.REACT_APP_API}auth/logout`,
          withCredentials: true,
        });
        if (res.data.includes("Logout")) {
          setUser(null);
          setToPop([]);
          setOrderCurrent([]);
          setOrderAdding([]);
          setToken("");
        }
      } catch (error) {
        console.log(error);
        return toast.error("Une erreur est survenue");
      }
    }
  };

  const logOutWhenDeleteAccount = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API}auth/logout`,
        withCredentials: true,
      });
      if (res.data.includes("Logout")) {
        setUser(null);
        setToken("");
      }
    } catch (error) {
      console.log(error);
      return toast.error("Une erreur est survenue");
    }
  };
  useEffect(() => {
    checkSessionIfExist();
  }, []);

  return (
    <ContextDynamic.Provider
      value={{
        formConnexionRegister,
        setFormConnexionRegister,
        user,
        setUser,
        token,
        setToken,
        admin,
        setAdmin,
        navCompo,
        setNavCompo,
        goPage,
        pop,
        setPop,
        toPop,
        setToPop,
        toPopMenus,
        setToPopMenus,
        orderCurrent,
        setOrderCurrent,
        orderAdding,
        setOrderAdding,
        popTableChoose,
        setPopTableChoose,
        handleLogout,
        logOutWhenDeleteAccount,
        loading,
        setLoading,
        codeTemporaire,
        setCodeTemporaire,
        cookies,
        setCookie,
        removeCookie,
      }}
    >
      {children}
    </ContextDynamic.Provider>
  );
};

export const Dynamic = () => useContext(ContextDynamic);
