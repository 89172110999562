import React from "react";
import styled from "styled-components";
import { MdPayments } from "react-icons/md";
import { FaCheckSquare } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { MdTableBar } from "react-icons/md";
import { FaGlobe } from "react-icons/fa6";
import { toast } from "react-toastify";
import { FaThumbsUp } from "react-icons/fa6";
import { FaThumbsDown } from "react-icons/fa6";
import { MdDeliveryDining } from "react-icons/md";
import axios from "axios";
import { SocketContext } from "../../../../Context/ContextSocket/ContextSocket";
import { COLORS } from "../../../../utils/Style/styleGlobale";
import { Dynamic } from "../../../../Context/ContextDynamic/ContextDynamic";

import { BsFillTelephoneFill } from "react-icons/bs";

const Order = ({
  codefast,
  idClientFast,
  table,
  note,
  order,
  idOrder,
  statusOrder,
  localisation,
  telephone,
}) => {
  const { socket } = SocketContext();
  const { user, setTotalCostAll } = Dynamic();
  const deleteOrderClick = async (id, table, nameClient) => {
    let textTodelete;
    if (!id) return toast.error("Identification de la commande impossible");
    if (table) {
      textTodelete = `Voulez-vous vraiment supprimer la commande de la table ${table} ?`;
    } else if (nameClient) {
      textTodelete = `Voulez-vous vraiment supprimer la commande de ${nameClient} ?`;
    } else {
      textTodelete = "Supprimer ?";
    }
    if (window.confirm(textTodelete)) {
      try {
        const res = await axios({
          method: "delete",
          url: `${process.env.REACT_APP_API}order/delete/${id}`,
          withCredentials: true,
        });
        // console.log(res);
        if (res.data.succes) {
          socket.emit("order-delete", { id: user._id });
          socket.off("order-delete");
          return toast.success(res.data.succes);
        }
        if (res.data.error) return toast.error(res.data.error);
      } catch (error) {
        console.log(error);
        return toast.error("Une erreur est survenue");
      }
    }
  };

  const readyOrder = async (id, table, name) => {
    let textToReady;
    if (!id) return toast.error("Identification de la commande impossible");
    if (statusOrder === 0) {
      if (table) {
        textToReady = `Informer que la commande de la table ${table} est prête ?`;
      } else if (name) {
        textToReady = `Informer que la commande de ${name} est prête ?`;
      } else {
        textToReady = "Prête ?";
      }
    }

    if (statusOrder === 2) {
      if (table) {
        textToReady = `Informer que la commande de la table ${table} n'est pas prête ?`;
      } else if (name) {
        textToReady = `Informer que la commande de ${name} n'est pas prête ?`;
      } else {
        textToReady = "Prête ?";
      }
    }

    if (window.confirm(textToReady)) {
      try {
        const res = await axios({
          method: "put",
          url: `${process.env.REACT_APP_API}order/update/ready/${id}`,
          withCredentials: true,
        });
        // console.log(res);
        if (res.data.succes) {
          socket.emit("order-valid", { id: user._id });
          socket.emit("valid-or-not-fast-client", {
            id: user._id,
            nameFast: idClientFast,
          });
          socket.off("order-valid");
          socket.off("valid-or-not-fast-client");
          return toast.success(res.data.succes);
        }
        if (res.data.error) return toast.error(res.data.error);
      } catch (error) {
        console.log(error);
        return toast.error("Une erreur est survenue");
      }
    }
  };

  const validOrderClick = async (id) => {
    if (!id)
      return toast.error("Des identifiants sont obligatoire pour continuer");
    if (statusOrder === 0)
      return toast.error(
        "Vous devez informer que la commande est prête avant de valider celle-ci"
      );
    let textOrder;
    if (statusOrder === 2) {
      textOrder = "Voulez-vous valider cette commande ? ";
    }
    if (statusOrder === 1) {
      textOrder = "Voulez-vous remetter cette commande en cours ? ";
    }
    if (window.confirm(textOrder)) {
      try {
        const res = await axios({
          method: "put",
          url: `${process.env.REACT_APP_API}order/update/${id}`,
          withCredentials: true,
        });
        // console.log(res);
        if (res.data.succes) {
          // console.log(res.data);
          socket.emit("order-valid", { id: user._id });
          socket.emit("valid-or-not-fast-client", {
            id: user._id,
            nameFast: idClientFast,
          });
          socket.off("order-valid");
          socket.off("valid-or-not-fast-client");
          return toast.success(res.data.succes);
        }
        if (res.data.error) return toast.error(res.data.error);
      } catch (error) {
        console.log(error);
        return toast.error("Une erreur est survenue");
      }
    }

    // try {
    //   const res = await axios({
    //     method: "put",
    //     url: `${process.env.REACT_APP_API}order/update/${id}`,
    //     withCredentials: true,
    //   });
    //   // console.log(res);
    //   if (res.data.succes) {
    //     // console.log(res.data);
    //     socket.emit("order-valid", { id: user._id });
    //     socket.emit("valid-or-not-fast-client", {
    //       id: user._id,
    //       nameFast: idClientFast,
    //     });
    //     socket.off("order-valid");
    //     socket.off("valid-or-not-fast-client");
    //     return toast.success(res.data.succes);
    //   }
    //   if (res.data.error) return toast.error(res.data.error);
    // } catch (error) {
    //   console.log(error);
    //   return toast.error("Une erreur est survenue");
    // }
  };
  // Fonction pour calculer le prix total de chaque commande
  const calculateTotalPrice = () => {
    let totalPrice = 0;
    const totalPricePerOrder = order.map((orde) => {
      Object.values(orde).forEach((products) => {
        products.forEach((product) => {
          // console.log(product);
          totalPrice += product.price;
        });
      });
      // return totalPrice.toFixed(2);
    });
    // setTotalPriceOrder(totalPrice);
    return totalPrice.toFixed(2);
    // console.log(totalPricePerOrder);
    // return totalPricePerOrder;
  };
  const displayListOrders = () => {
    const handleUlClickToChangeStyle = async (
      event,
      idOrder,
      order,
      category,
      item
    ) => {
      const ulElement = event.currentTarget;
      ulElement.classList.toggle("passed");
      // socket.emit("change-style", { idUser: user._id, idOrder: idOrder });
    };
    // console.log(order);
    return order.map((ord, index) => (
      <li key={index} className="li-box-parent-categorie">
        {Object.keys(ord).map((category) => (
          <div key={category}>
            <h3>{category}</h3>
            {ord[category].map((item, itemIndex) => (
              <div
                key={itemIndex}
                className="parent-ul-info-order"
                onClick={(e) =>
                  handleUlClickToChangeStyle(e, idOrder, order, category, item)
                }
              >
                {Object.keys(item).map((key) => (
                  <ul key={key} className="ul-info-order">
                    {/* <li className="li-others">
                      {item[key] !== item.price && item[key]}
                    </li> */}
                    {item[key] !== item.price &&
                      item[key] !== item.miniValid && (
                        <li className="li-others">{item[key]}</li>
                      )}
                    {item[key] === item.price && (
                      <li className="li-to-price">{item.price.toFixed(2)} €</li>
                    )}
                  </ul>
                ))}
              </div>
            ))}
          </div>
        ))}
      </li>
    ));
  };

  const searchAdressInMap = (address) => {
    const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      address
    )}`;
    window.open(url, "_blank");
  };

  return (
    <StyledOrder $css={statusOrder}>
      <div className="title-to-legends">
        <div className="box-to-action-order">
          {codefast ? (
            <span>
              <FaGlobe className="the-glob-to-order-by-internet" />
              {idClientFast ? idClientFast : table}
            </span>
          ) : (
            <span>
              <MdTableBar /> {table}
            </span>
          )}
          <MdDelete
            className="delete-order"
            onClick={() => deleteOrderClick(idOrder, table, idClientFast)}
          />{" "}
          {statusOrder !== 2 && statusOrder === 0 && (
            <FaThumbsUp
              className="ready-order"
              onClick={() => readyOrder(idOrder, table, idClientFast)}
            />
          )}
          {statusOrder === 2 && statusOrder !== 0 && (
            <FaThumbsDown
              className="ready-order"
              onClick={() => readyOrder(idOrder, table, idClientFast)}
            />
          )}
          <FaCheckSquare
            className="valid-order"
            onClick={() => validOrderClick(idOrder)}
          />
          <strong>
            <MdPayments /> * {calculateTotalPrice()} €
          </strong>
        </div>
        {note && <p className="if-Note">Note : {note}</p>}
        {localisation && (
          <p
            className="info-to-delivery"
            onClick={() => searchAdressInMap(localisation)}
          >
            <MdDeliveryDining className="ico-delivery" /> - {localisation}
          </p>
        )}
        {telephone && (
          <a href={`tel:${telephone}`} className="to-call">
            <BsFillTelephoneFill /> {telephone}
          </a>
        )}
        <div className="display-boucl">{displayListOrders()}</div>
      </div>
    </StyledOrder>
  );
};

export default Order;

const StyledOrder = styled.div`
  width: 45%;
  height: 45vh;
  overflow-x: scroll;
  background: ${({ $css }) => ($css > 0 ? "#9b9b9b" : "#404040")};
  padding-bottom: 5px;
  margin: 10px;
  display: flex;
  flex-wrap: wrap;
  border-radius: 10px;
  &::-webkit-scrollbar {
    display: none;
  }
  .info-to-delivery {
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    margin: 5px 0px;
    font-size: 0.8em;
    cursor: pointer;
    user-select: all;
    -webkit-user-select: auto;
    .ico-delivery {
      font-size: 1.5em;
    }
  }
  .to-call {
    display: block;
    /* width: auto; */
    width: 40%;
    text-align: center;
    padding: 3px;
    border-radius: 5px;
    margin: 0px auto;
    font-size: 0.8em;
    cursor: pointer;
    color: white;
    /* background: white; */
    background: orange;
  }
  .title-to-legends {
    width: 100%;
    position: relative;
  }
  .if-Note {
    padding: 3px;
    background: ${COLORS.red};
    text-align: center;
  }
  .title-to-legends > .box-to-action-order {
    position: sticky;
    /* background: #404040; */
    background: ${({ $css }) => ($css === 2 ? COLORS.facebook : "#404040")};
    width: 100%;
    top: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }
  .the-glob-to-order-by-internet {
    margin-right: 5px;
  }
  .parent-ul-info-order {
    margin: 5px;
    display: flex;
    position: relative;
    flex-direction: column;
  }
  .display-boucl {
    height: 100%;
    width: 100%;
  }
  .li-box-parent-categorie {
    margin-bottom: 5px;
    border-bottom: dashed 2px orange;
    padding: 5px;
    h3 {
      color: orange;
    }
    .li-others {
      margin-bottom: 3px;
      color: white;
    }
    .ul-info-order {
      background: ${COLORS.greenTransparent};
      display: flex;
      padding: 3px;
      flex-direction: row;
      /* position: relative; */
      /* li {
        color: ${COLORS.green};
      } */
    }
    .passed {
      background: grey !important;
    }
    .li-to-price {
      position: absolute;
      top: 0px;
      right: 0px;
      /* margin-left: 10px; */
      padding: 3px;
      text-align: center;
      /* width: 25%; */
      font-size: 0.7em;
      border-radius: 0px 0px 0px 3px;
      background: orange;
    }
  }
  .title-to-legends > .box-to-action-order > span {
    background: pink;
    /* width: 15%; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    border-radius: 3px;
  }
  .title-to-legends > .box-to-action-order > strong {
    background: ${COLORS.greenTransparent};
    color: white;
    display: flex;
    align-items: center;
    padding: 5px;
    border-radius: 3px;
  }
  .title-to-legends > .box-to-action-order > .delete-order {
    font-size: 2em;
    color: ${COLORS.red};
    background: #404040;
    /* box-shadow: 6px 6px 11px #363636, -6px -6px 11px #4a4a4a; */
    box-shadow: 6px 6px 11px
        ${({ $css }) => ($css === 2 ? COLORS.facebook : "#4a4a4a")},
      -6px -6px 11px ${({ $css }) => ($css === 2 ? COLORS.facebook : "#4a4a4a")};
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
  }
  .valid-order {
    font-size: 2em;
    /* color: ${COLORS.green}; */
    color: ${COLORS.checkOrder};
    background: #404040;
    /* box-shadow: 6px 6px 11px  #363636, -6px -6px 11px #4a4a4a; */
    box-shadow: 6px 6px 11px
        ${({ $css }) => ($css === 2 ? COLORS.facebook : "#363636")},
      -6px -6px 11px ${({ $css }) => ($css === 2 ? COLORS.facebook : "#4a4a4a")};
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
  }
  .ready-order {
    font-size: 2em;
    color: ${COLORS.facebook};
    background: #404040;
    box-shadow: 6px 6px 11px
        ${({ $css }) => ($css === 2 ? COLORS.facebook : "#363636")},
      -6px -6px 11px ${({ $css }) => ($css === 2 ? COLORS.facebook : "#4a4a4a")};
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
  }

  .valid-order:active,
  .title-to-legends > .box-to-action-order > .delete-order:active {
    box-shadow: inset 20px 20px 60px #363636, inset -20px -20px 60px #4a4a4a;
  }

  //width >= 800px
  @media screen and (max-width: 800px) {
    .box-to-action-order > span > .the-glob-to-order-by-internet {
      font-size: 1.1em;
    }
    .title-to-legends > .box-to-action-order > .delete-order {
      font-size: 1.5em;
      padding: 2px;
    }
    .ready-order,
    .valid-order {
      font-size: 1.5em;
      padding: 2px;
    }
  }

  //width >= 425px
  @media screen and (max-width: 425px) {
    width: 90%;
  }
  //width =< 428px
  @media screen and (max-width: 429px) {
    width: 90%;
  }
`;
