import axios from "axios";
import React from "react";
import { toast } from "react-toastify";
import styled from "styled-components";

const Categorie = ({
  categories,
  setCallGetCategoriesAgain,
  callGetCategoriesAgain,
}) => {
  const deleteCategorie = async (id, name) => {
    if (
      window.confirm(
        `Voulez-vous vraiment supprimer ${name} ? Ok pour supprimer`
      )
    ) {
      try {
        const res = await axios({
          method: "delete",
          url: `${process.env.REACT_APP_API}categorie/delete/${id}`,
          withCredentials: true,
        });
        if (res.data.succes) {
          setCallGetCategoriesAgain(!callGetCategoriesAgain);
          return toast.success(res.data.succes);
        }
        if (res.data.error) return toast.error(res.data.error);
      } catch (error) {
        console.log(error);
        toast.error("Une erreur est survenue");
      }
    }
  };
  return (
    <StyledCategorie className="list-categorie">
      {categories &&
        categories.length > 0 &&
        categories.map((categorie) => {
          return (
            <li
              key={categorie._id}
              onClick={() =>
                deleteCategorie(categorie._id, categorie.nameCategorie)
              }
            >
              {categorie.nameCategorie}
            </li>
          );
        })}
    </StyledCategorie>
  );
};

export default Categorie;
const StyledCategorie = styled.ul`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 5px;
  li {
    background: pink;
    margin: 10px;
    padding: 5px;
    border-radius: 5px;
    background: #bab5b5;
    box-shadow: 7px 7px 15px #979393, -7px -7px 15px #ddd7d7;
  }
  li:active {
    background: #bab5b5;
    box-shadow: inset 7px 7px 15px #979393, inset -7px -7px 15px #ddd7d7;
  }
  //width >= 425px
  @media screen and (max-width: 429px) {
    .list-categorie {
      display: flex;
      flex-wrap: wrap;
    }
    .list-categorie > li {
      margin: 5px;
    }
  }
`;
