import React, { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import styled from "styled-components";
import { COLORS } from "../../../../utils/Style/styleGlobale";
import { Data } from "../../../../Context/ContextData/ContextData";
import Product from "./Product";
import { MdNavigateNext } from "react-icons/md";
const ListProduitSelectByCategorie = ({ cateSelect, setCateSelect }) => {
  const [produits, setProduits] = useState([]);
  const [callAgain, setCallAgain] = useState(false);
  const { dataMongo } = Data();

  const catchProduitsCurrent = async () => {
    if (dataMongo && dataMongo.length > 0) {
      dataMongo.map((item) => {
        Object.keys(item).map((key) => {
          if (key === cateSelect) {
            setProduits(item[key]);
          }
        });
      });
    }
  };
  useEffect(() => {
    catchProduitsCurrent();
  }, [dataMongo]);

  return (
    <StyledListProduitSelectByCategorie>
      <div className="box-close">
        <h2>
          {" "}
          <MdNavigateNext /> {produits && produits.length}{" "}
          {cateSelect && cateSelect}
        </h2>
        <MdClose className="icon-close" onClick={() => setCateSelect("")} />
      </div>
      <div className="list-products">
        {produits &&
          produits.length > 0 &&
          produits.map((product) => (
            <Product
              product={product}
              key={product._id}
              setCallAgain={setCallAgain}
              callAgain={callAgain}
            />
          ))}
      </div>
    </StyledListProduitSelectByCategorie>
  );
};

export default ListProduitSelectByCategorie;
const StyledListProduitSelectByCategorie = styled.div`
  position: absolute;
  background: ${COLORS.green};
  height: 100%;
  width: 90%;
  padding: 10px;
  top: 50%;
  right: 50%;
  border-radius: 10px;
  transform: translate(50%, -50%);
  .list-products {
    /* background: green; */
    height: 90%;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .box-close {
    width: 100%;
    /* text-align: right; */
    display: flex;
    justify-content: space-between;
    background: ${COLORS.grey};
    border-radius: 3px;
    padding: 5px;
    h2 {
      display: flex;
      align-items: center;
      color: ${COLORS.green};
    }
    .icon-close {
      color: ${COLORS.btnColor};
      background: ${COLORS.green};
      border-radius: 50px;
      font-size: 2em;
      cursor: pointer;
    }
  }
  //width >= 425px
  @media screen and (max-width: 429px) {
    width: 90%;
    height: 90%;
    .box-close > h2 {
      font-size: 1.3em;
    }
  }
`;
