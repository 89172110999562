import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS } from "../Style/styleGlobale";
import { MdAddCircle, MdCancel } from "react-icons/md";
import { Dynamic } from "../../Context/ContextDynamic/ContextDynamic";
import { OrderCurrent } from "../../Context/ContextOrderCurrent/ContextOrderCurrent";
import { Data } from "../../Context/ContextData/ContextData";
import { toast } from "react-toastify";

const PopJustMenus = () => {
  const [textSearch, setTextSearch] = useState("");
  const [menuToDisplay, setMenuToDisplay] = useState([]);
  const { setToPopMenus } = Dynamic();
  const { tableCurrent, setOrderCurrentMenu } = OrderCurrent();
  const { menusMongo } = Data();
  const searchElement = (e) => {
    const searching = e.target.value.toLowerCase();
    setTextSearch(e.target.value);
    const filteredData = menuToDisplay.filter((el) =>
      el.nameMenu.toLowerCase().includes(searching)
    );
    setMenuToDisplay(filteredData);
  };

  const addMenuInOrderCurrent = (name, description, price) => {
    // alert(`Menu ${name} la description ${description} et le prix ${price}`);
    // const menu = { menu: name, description, price, miniValid: 0 };
    const menu = { name: name, description, price };
    setOrderCurrentMenu((prev) => [menu, ...prev]);
    toast.success(`${name} ajouté à la commande en cours`);
  };
  useEffect(() => {
    if (!textSearch) {
      setMenuToDisplay(menusMongo);
    }
  }, [textSearch]);
  return (
    <StyledPopJustMenus>
      <MdCancel
        onClick={() => setToPopMenus(false)}
        className="close-pop-menu"
      />
      <h2>Table N°{tableCurrent} - Menus</h2>
      <input
        type="search"
        onChange={(e) => searchElement(e)}
        placeholder="Recherche"
      />
      <ul>
        {menuToDisplay && menuToDisplay.length > 0 ? (
          menuToDisplay.map((menu) => (
            <div key={menu._id} className="box-child-to-li">
              <div className="box-sous-child-info-on-menus">
                <li>
                  <strong>Menu : </strong>
                  {menu.nameMenu}
                </li>
                <li className="li-description-menu">
                  <strong>Description :</strong> {menu.infos}
                </li>
                <li>
                  <strong>Prix :</strong> {menu.price.toFixed(2)} €
                </li>
              </div>
              <div className="btn-action-add-current-order">
                <MdAddCircle
                  className="btn-add"
                  onClick={() =>
                    addMenuInOrderCurrent(menu.nameMenu, menu.infos, menu.price)
                  }
                />
              </div>
            </div>
          ))
        ) : (
          <li>Aucun menu</li>
        )}
      </ul>
    </StyledPopJustMenus>
  );
};

export default PopJustMenus;

const StyledPopJustMenus = styled.div`
  background: ${COLORS.greenTransparent};
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  position: absolute;
  z-index: 50;
  height: 100vh;
  width: 100%;
  padding: 10px;
  .close-pop-menu {
    color: ${COLORS.red};
    font-size: 1.5em;
  }
  h2 {
    text-align: center;
    color: white;
  }
  input {
    outline: none;
    border: none;
    border-radius: 5px;
    padding: 3px;
    font-size: 1.1em;
    margin: 10px 0px;
  }
  ul {
    height: 80%;
    display: flex;
    flex-direction: column;
    /* background: orange; */
    overflow-x: scroll;
  }
  ul > .box-child-to-li {
    /* background: pink; */
    margin-top: 15px;
    padding: 10px 0px;
    display: flex;
    border-bottom: dashed 2px white;
  }
  ul > .box-child-to-li > .box-sous-child-info-on-menus {
    width: 90%;
    /* background: white; */
  }
  ul > .box-child-to-li > .box-sous-child-info-on-menus > .li-description-menu {
    /* background: orange; */
    height: 5vh;
    width: 70%;
    overflow-x: scroll;
  }
  ul > .box-child-to-li > .btn-action-add-current-order {
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ul > .box-child-to-li > .btn-action-add-current-order > .btn-add {
    font-size: 1.3em;
  }
`;
