export const COLORS = {
  // green: "#03d97d",
  // green: "#e43e5a",
  // green: "#1c1b2f",
  green: "#1f4068",
  greelReal: "#08c018",
  validOnBlue: "#87f565",
  checkOrder: "#03d97d",
  btnshadow: "#1c1b2f",
  btnColor: "#e43e5a",
  red: "#f56565",
  grey: "#bab5b5",
  greenTransparent: "#03d97d33",
  blackgrey: "#676565",
  facebook: "#1877f2",
  instagram: "#833ab4",
};
