import React, { useState } from "react";
import styled from "styled-components";
import { MdDelete } from "react-icons/md";
import { ImSwitch } from "react-icons/im";
import { MdMode } from "react-icons/md";
import { COLORS } from "../../../../utils/Style/styleGlobale";
import { Data } from "../../../../Context/ContextData/ContextData";
import { toast } from "react-toastify";
import axios from "axios";
import UpdateProduct from "./UpdateProduct";

const Product = ({ product, callAgain, setCallAgain }) => {
  //   console.log(product);
  const [update, setUpdate] = useState(false);
  const [productUpdate, setProductUpdate] = useState({});
  const { getAllData } = Data();
  const deleteProduct = async (id, name) => {
    if (!id) return toast.error("Identification du produit impossible");
    if (window.confirm(`Supprimer ${name} ? Ok pour supprimer`)) {
      try {
        const res = await axios({
          method: "delete",
          url: `${process.env.REACT_APP_API}product/delete/${id}`,
          withCredentials: true,
        });
        //   console.log(res);
        if (res.data.succes) {
          //   getAllProducts();
          getAllData(); // to url :  /order
          return toast.success(res.data.succes);
        }
        if (res.data.error) return toast.error(res.data.error);
      } catch (error) {
        console.log(error);
        return toast.error("Une erreur est survenue");
      }
    }
  };

  const actifOrNot = async (id) => {
    if (!id) return toast.error("Identification du produit impossible");
    try {
      const res = await axios({
        method: "put",
        url: `${process.env.REACT_APP_API}product/update/${id}`,
        withCredentials: true,
        data: { activ: 1 },
      });
      // console.log(res);
      if (res.data.error) return toast.error(res.data.error);
      if (res.data.succes) {
        // getAllProducts();
        getAllData(); //to url :  /order
        setCallAgain(!callAgain);
        return toast.success(res.data.succes);
      }
    } catch (error) {
      console.log(error);
      return toast.error("Une erreur est survenue lors de mise-à-jour");
    }
  };

  const updateProduct = (id, infos, name, price, status, categorie) => {
    if (!id) return toast.error("Echec, Identifiant introuvable");
    setProductUpdate({
      _id: id,
      infos,
      nameProduct: name,
      price,
      status,
      categorie,
    });
    setUpdate(true);
  };
  return (
    <StyledProduct $css={product.status}>
      {update && (
        <UpdateProduct
          setUpdate={setUpdate}
          productUpdate={productUpdate}
          setProductUpdate={setProductUpdate}
        />
      )}
      <ul>
        <div className="info">
          <li>
            <strong>Produit : </strong>
            {product.nameProduct}
          </li>
          <li>
            <strong>Catégorie : </strong>
            {product.categorie}
          </li>
          {product.infos && (
            <li>
              <strong>Option : </strong>
              {product.infos}
            </li>
          )}
          <li>
            <strong>Prix : </strong>
            {product.price.toFixed(2)}€
          </li>
          <li>
            <strong>Etat : </strong>
            {product.status === 1 ? "Actif 🔥" : "Non actif 🤧"}
          </li>
        </div>
        <div className="action">
          <MdDelete
            className="icon delete"
            onClick={() => deleteProduct(product._id, product.nameProduct)}
          />
          <MdMode
            className="icon update"
            onClick={() =>
              updateProduct(
                product._id,
                product.infos,
                product.nameProduct,
                product.price,
                product.status,
                product.categorie
              )
            }
          />
          <ImSwitch
            className="icon switch"
            onClick={() => actifOrNot(product._id)}
          />
        </div>
      </ul>
    </StyledProduct>
  );
};

export default Product;

const StyledProduct = styled.div`
  background: ${({ $css }) => ($css ? "greenyellow" : COLORS.grey)};
  margin: 15px 0px;
  padding: 10px;
  border-radius: 5px;
  ul {
    display: flex;
    .info {
      /* background: blue; */
      width: 70%;
      li {
        margin-top: 5px;
        color: ${COLORS.green};
      }
    }
    .action {
      width: 30%;
      /* background: pink; */
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      .icon {
        font-size: 1.6em;
      }
      .delete {
        color: ${COLORS.red};
        cursor: pointer;
      }
      .delete:active {
        color: white;
      }
      .update {
        color: ${COLORS.greelReal};
        cursor: pointer;
      }
      .update:active {
        color: white;
      }
      .switch {
        color: ${({ $css }) => ($css ? COLORS.red : COLORS.greelReal)};
        cursor: pointer;
      }
      .switch:active {
        color: white;
      }
    }
  }
  //width >= 425px
  @media screen and (max-width: 429px) {
    padding: 0px;
    .info {
      width: 80%;
    }
    .action {
      width: 20%;
      display: flex;
      flex-direction: column;
      .icon {
        font-size: 1.4em !important;
      }
    }
  }
`;
