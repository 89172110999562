import React from "react";
import { Dynamic } from "../../Context/ContextDynamic/ContextDynamic";
import { Navigate } from "react-router-dom";

const PrivateRouteClientHome = ({ children }) => {
  const { cookies } = Dynamic();

  if (!cookies.accesClient) {
    return <Navigate to="/client/identification" />;
  }
  return children;
};

export default PrivateRouteClientHome;
