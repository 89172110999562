import React, { useEffect, useState } from "react";
import { MdTableBar } from "react-icons/md";
import ButtonsChoose from "../../utils/Buttons/ButtonsChoose";
import styled from "styled-components";
import { Dynamic } from "../../Context/ContextDynamic/ContextDynamic";
import { COLORS } from "../../utils/Style/styleGlobale";
import { Data } from "../../Context/ContextData/ContextData";
const Modal = () => {
  const [callAgain, setCallAgain] = useState(false);
  const { setPopTableChoose, setToPopMenus, token } = Dynamic();
  const { setDataToPopUp, dataMongo, menusMongo } = Data();

  const displayCategoriesFromDataBase = () => {
    const selectCategorie = (key) => {
      // console.log(key);
      //on envoie le nom (key) aux context dynamic
      setDataToPopUp(key);
    };
    if (dataMongo && dataMongo.length > 0) {
      return dataMongo.map((item, index) => {
        let keys = Object.keys(item);
        return keys.map((key) => (
          <div
            className="box-btns"
            key={index}
            onClick={() => selectCategorie(key)}
          >
            <li>{key}</li>
          </div>
        ));
      });
    }
  };

  const displayMenusIfExist = () => {
    if (menusMongo && menusMongo.length > 0) {
      return (
        <div
          className="box-btns to-menu color-btn-menu"
          onClick={() => setToPopMenus(true)}
        >
          <li>Menus</li>
        </div>
      );
    }
  };

  useEffect(() => {
    if (!callAgain) {
      setCallAgain(!callAgain);
    }
  }, []);
  return (
    <StyledModal>
      <div className="choose-table">
        <ButtonsChoose
          // text="Table"
          actionClick={() => setPopTableChoose(true)}
          icon={<MdTableBar className="all-icon" />}
        />
      </div>
      <ul className="choose-one-thing">
        {displayCategoriesFromDataBase()}
        {displayMenusIfExist()}
      </ul>
    </StyledModal>
  );
};

export default Modal;

const StyledModal = styled.div`
  background: #cccccc;
  padding: 10px;
  width: 60%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px 15px;
  .choose-table {
    width: 50%;
    padding: 10px;
    border-radius: 10px;
    /* background: ${COLORS.green}; */
    margin: 10px;
    cursor: pointer;
    background: #cccccc;
    box-shadow: 7px 7px 15px #a5a5a5, -7px -7px 15px #f3f3f3;
  }
  .choose-table:active {
    box-shadow: inset 7px 7px 15px #a5a5a5, inset -7px -7px 15px #f3f3f3;
  }
  .choose-one-thing {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    background: #8a8a8a;
    border-radius: 10px;
    padding: 10px;
  }
  .choose-one-thing > .box-btns {
    /* margin: 5px; */
    width: 40%;
    padding: 10px;
    border-radius: 10px;
    /* background: greenyellow; */
    /* background: ${COLORS.green}; */
    box-shadow: 7px 7px 15px #707070, -7px -7px 15px #a4a4a4;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .choose-one-thing > .box-btns:active {
    box-shadow: inset 7px 7px 15px #707070, inset -7px -7px 15px #a4a4a4;
  }
  .choose-one-thing > .box-btns-last {
    width: 60%;
    padding: 10px;
    border-radius: 10px;
    /* background: greenyellow; */
    /* background: ${COLORS.green}; */
    box-shadow: 7px 7px 15px #707070, -7px -7px 15px #a4a4a4;
    cursor: pointer;
  }
  .choose-one-thing > .box-btns-last:active {
    box-shadow: inset 7px 7px 15px #707070, inset -7px -7px 15px #a4a4a4;
  }
  .color-btn-menu > li {
    color: ${COLORS.green};
    font-size: 1.5em;
    font-weight: 700;
  }
  //tablette 768px
  @media screen and (max-width: 768px) {
    width: 80%;
    .choose-one-thing > .box-btns {
      margin: 10px;
    }
  }
`;
