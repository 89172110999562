import React from "react";
import styled from "styled-components";
import { COLORS } from "../Style/styleGlobale";

const Loading = () => {
  return (
    <StyledLoading>
      <span className="loader"></span>
    </StyledLoading>
  );
};

export default Loading;

const StyledLoading = styled.div`
  height: 100vh;
  /* position: absolute; */
  position: fixed;
  z-index: 90;
  width: 100%;
  top: 0px;
  background: ${COLORS.greenTransparent};
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
  .loader {
    width: 40px;
    height: 98px;
    display: inline-block;
    position: relative;
    border: 2px solid #fff;
    box-sizing: border-box;
    color: rgba(255, 61, 0, 0.9);
    border-radius: 20px 20px 4px 4px;
    background: #fff;
    animation: fill 2s linear infinite alternate;
  }
  .loader::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 0%;
    transform: translate(-50%, -95%);
    border: 2px solid #fff;
    border-bottom: none;
    background: #fff;
    width: 15px;
    height: 35px;
    animation: fillNeck 2s linear infinite alternate;
  }

  @keyframes fill {
    0% {
      box-shadow: 0 0 inset;
    }
    50%,
    100% {
      box-shadow: 0 -98px inset;
    }
  }

  @keyframes fillNeck {
    0%,
    50% {
      box-shadow: 0 0 inset;
    }
    100% {
      box-shadow: 0 -20px inset;
    }
  }
`;
